import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { authOperations } from '../authorization/duck';
import { logout } from '../authorization/duck/operations';
import './headerBar.scss';
import i18n from '../i18n';

class HeaderBar extends React.Component {
    state = {
        language: 'en'
    }

    onLanguageChange = (event) => {
        if(event.target.value) {
            i18n.changeLanguage(event.target.value);

            this.setState({
                ...this.state,
                language: event.target.value
            })

            localStorage.setItem('defaultLanguage', event.target.value);
        }
    }

    componentDidMount() {
        const language = localStorage.getItem('defaultLanguage');

        if(language) {
            this.setState({
                ...this.state,
                language
            })

            i18n.changeLanguage(language);
        }
    }

    render() {
        return (
            <div>
                <nav>
                    <div style={{height: '100%'}}>
                    <Link to="/home">
                        <img src="/imgs/prod-logo.png" alt="lOGO" width="120px" height="50px" />
                    </Link>
                    <ul className="nav-links">
                        {/* <Link to="/" onClick={() => logout()} >
                            <FontAwesomeIcon icon={faSignOutAlt} />  Logout
                        </Link>
                        <Link to="/" onClick={() => logout()} >
                            <FontAwesomeIcon icon={faSignOutAlt} />  Logout
                        </Link> */}
                        <li className="navItem language-dropdown">
                            <select className="form-control form-sm" onChange={this.onLanguageChange} value={this.state.language}>
                                <option value="en">English</option>
                                <option value="ar">Arabic</option>
                            </select>
                        </li>
                        <li className="navItem">
                            {
                                localStorage.getItem('userPicture') ? 
                                <img src={localStorage.getItem('userPicture')} alt="student-teacher" className="user-picture"/> 
                                :
                                <div className='icon-button icon-buttom-sm'>
                                    {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
                                    {/* <img src="https://image.flaticon.com/icons/png/512/67/67902.png" alt="student-teacher" className="no-padding-margin profile-picture"/> */}
                                    {
                                        localStorage.getItem('role') === 'teacher' ? 
                                        <img src="/imgs/icons/teacher-white.png" alt="student-teacher" className="no-padding-margin profile-picture"/>
                                        :
                                        <img src="/imgs/icons/student-white.png" alt="student-teacher" className="no-padding-margin profile-picture"/>
                                    }
                                </div>
                            }   
                        </li>
                        <li className="navItem no-padding-margin">
                            <h6 style={{margin: '1.2px'}}>
                                { localStorage.getItem("firstName") + " " + localStorage.getItem("lastName") }
                            </h6>
                        </li>
                        <li className="navItem" style={{margin: '5px'}}>
                            <Link to="/" onClick={() => logout()} className='icon-button'>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </Link>
                        </li>
                    </ul>
                    {/* <ul className="nav-links">
                        <h4>
                            <FontAwesomeIcon icon={faSignOutAlt} />  { localStorage.getItem('firstName') + " " + localStorage.getItem('lastName') }
                        </h4>
                        <Link to="/" onClick={() => logout()} >
                            Logout <FontAwesomeIcon icon={faSignOutAlt} /> 
                        </Link>
                    </ul> */}
                    </div>
                </nav>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch)
    };
}

export default connect(mapDispatchToProps)(HeaderBar);