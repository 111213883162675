import "react-datepicker/dist/react-datepicker.css";
import {
    React, connect, bindActionCreators, moment,
    FontAwesomeIcon, faTrashAlt, faPlus, faCheck, faTimes, Image,
    DatePicker, Button, Link,// Dropdown, DropdownButton,
    HeaderBar, Form, fetchSelectedQuiz, ResultList,
    Row, Col, Container, uploadQuizImage, fetchQuizID, updateQuiz, fetchQuizResult, fetchClasses, fetchSubject, deleteQuiz
} from '../index';
import './style.scss';
import { Toast } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class UpdateQuiz extends React.Component {
    state = {
        isUpdatingQuiz: false,
        title: '',
        questions: [],
        start_date: '',
        end_date: '',
        classes: [],
        subjects: [],
        toast: {
            show: false,
            message: null,
            title: null
        },
        localize: this.props.t
    }

    componentDidMount() { this.fetchQuiz(); }
    fetchQuiz = async () => {
        this.setState({ isFetchingQuiz: true })
        try {
            let quizList = await this.props.actions.fetchSelectedQuiz(this.props.match.params.id);

            this.setState({
                questions: quizList.data.questions,
                startDate: moment(quizList.data.start_date).valueOf(),
                endDate: moment(quizList.data.end_date).valueOf(),
                title: quizList.data.title,
                className: quizList.data.className,
                classID: quizList.data.class_id,
                subjectName: quizList.data.subjectName,
                subjectID: quizList.data.subject_id,
            });

            this.getClasses();
            this.getSubjects();
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingQuiz: false })
    }

    showModal = () => { this.setState({ showResultModal: !this.state.showResultModal }); };

    setQuizIDResult = async () => {
        this.showModal();
        let quiz = await this.props.actions.fetchQuizID(this.props.match.params.id);
        if (quiz.payload) {
            let result = await this.props.actions.fetchQuizResult(quiz.payload);
            this.setState({ quizList: result })
        }
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if(e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => {
                    this.getSubjects()
                });
            }

        }
    }
    onSelectSubject = (e) => {
        if (e.target.value) {
            if(e.target.value !== "Select Subject") {
                this.setState({ subjectID: e.target.value });
            }
        }
    }

    async getClasses() {
        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes
            })
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    }

    handleStartDate = (date) => {
        const start = new Date(date);
        this.setState({ startDate: start });
    }
    handleEndDate = (date) => {
        const end = new Date(date);
        this.setState({ endDate: end });
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    }

    isEmpty = (value) => value !== undefined && value.toString().trim() === "";

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            }
        })
    }

    updateQuiz = async () => {
        this.setState({ isUpdatingQuiz: true });
        try {
            let questions = [...this.state.questions];

            if (this.isEmpty(this.state.title)) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('quizComponent.validation.title'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            for (let i = 0; i < questions.length; i++) {
                // Validation
                if (this.isEmpty(questions[i].question.Q)) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('quizComponent.validation.question'),
                            title: this.state.localize('shared.static.warning')
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }

                /* if (this.isEmpty(questions[i].description)) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: "Question description is missing."
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                } */

                if (questions[i].question.type === "img" && questions[i].question.Q.startsWith('data')) {
                    let questionImage = await this.props.actions.uploadQuizImage(document.getElementById(`question-${i}`).files[0]);

                    if (questionImage.success) {
                        questions[i].question.Q = questionImage.data;
                    }
                }

                let hasOneCorrectOption = false;

                for (let j = 0; j < questions[i].options.length; j++) {
                    if (questions[i].options[j].isCorrect) {
                        hasOneCorrectOption = true;
                    }

                    if (this.isEmpty(questions[i].options[j].choice)) {
                        this.setState({
                            toast: {
                                show: !this.state.toast.show,
                                message: this.state.localize('quizComponent.validation.questionMissing'),
                                title: this.state.localize('shared.static.warning')
                            }
                        });

                        setTimeout(this.hideToast, 3000);

                        return;
                    }

                    if (questions[i].options[j].type === "img" && questions[i].options[j].choice.startsWith('data')) {
                        let optionImage = await this.props.actions.uploadQuizImage(document.getElementById(`question-${i}-option-${j}`).files[0]);

                        if (optionImage.success) {
                            questions[i].options[j].choice = optionImage.data;
                        }
                    }
                }

                if (!hasOneCorrectOption) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('quizComponent.validation.correctOption', { counter: i + 1}),
                            title: this.state.localize('shared.static.warning')
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }
            }

            let updateQuiz = await this.props.actions.updateQuiz(
                this.props.match.params.id,
                this.state.title,
                questions,
                moment(this.state.startDate),
                moment(this.state.endDate)
            );
            if (updateQuiz) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.updated', { module: this.state.localize('pages.quiz', {action: ''}) }),
                        title: this.state.localize('shared.static.success')
                    }
                });

                setTimeout(this.hideToast, 3000);



                setTimeout(() => this.props.history.replace('/quiz'), 3000);
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || error,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
        this.setState({ isUpdatingQuiz: false });
    }

    handleTitle = (event) => { this.setState({ title: event.target.value }); }
    handleStartDate = (date) => {
        const start = new Date(date);
        this.setState({ startDate: start });
    }
    handleEndDate = (date) => {
        const end = new Date(date);
        this.setState({ endDate: end });
    }

    handleQuestion = i => e => {
        let questions = [...this.state.questions];
        questions[i].question.Q = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions)
        });
    }

    handleQuestionDescription = i => e => {
        let questions = [...this.state.questions];
        questions[i].description = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions[i].description)
        });
    }

    addQuestion = e => {
        e.preventDefault()
        let questions = this.state.questions.concat([{
            question: { Q: "", type: "" },
            description: "",
            options: [{ choice: "", type: "text", isCorrect: false }, { choice: "", type: "text", isCorrect: false }]
        }])
        this.setState({
            questions
        })
    }

    deleteQuestion = i => async e => {
        e.preventDefault()
        let questions = [...this.state.questions];

        if (i !== -1) {
            const hasQuizStarted = await this.hasQuizStarted(questions);

            if (!hasQuizStarted) {
                questions.splice(i, 1);

                this.setState({
                    questions
                })
            }
        }
    }

    addOption = i => e => {
        e.preventDefault();
        let options = this.state.questions[i].options.push({ choice: '', type: 'text', isCorrect: false })
        this.setState({
            options
        })
    }

    deleteOption = (i, j) => async e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        if (j !== -1) {
            const hasQuizStarted = await this.hasQuizStarted(questions);

            if (!hasQuizStarted) {
                questions[i].options.splice(j, 1);

                this.setState({
                    questions
                })
            }
        }
    }

    hasQuizStarted = async (q) => {
        try {
            let updateQuiz = await this.props.actions.updateQuiz(
                this.props.match.params.id,
                this.state.title,
                q,
                moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
            );

            if (updateQuiz) {
                return false;
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);

            return true;
        }
    }

    correctOptionSelected = (i, j) => () => {
        let questions = [...this.state.questions];

        questions[i].options.map((option) => {
            if (option.isCorrect) {
                option.isCorrect = false;
            }

            return option;
        })

        questions[i].options[j].isCorrect = true;

        this.setState({
            questions
        })
    }

    handleQuestionOption = (i, j) => e => {
        let questions = [...this.state.questions];
        questions[i].options[j].choice = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions)
        });
    }

    handleQuestionType = i => e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        if (e.target.value === "img") {
            questions[i].question.type = "img";

            if (questions[i].question.Q) {
                questions[i].question.Q = "";
            }
        } else {
            questions[i].question.type = "text";

            if (questions[i].question.Q) {
                questions[i].question.Q = "";
            }
        }

        this.setState({
            questions
        })
    }

    handleQuestionOptionType = (i, j) => e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        console.log(e.target.value);

        if (e.target.value === "Image") {
            questions[i].options[j].type = "img";

            if (questions[i].options[j].choice) {
                questions[i].options[j].choice = "";
            }
        } else {
            questions[i].options[j].type = "text";

            if (questions[i].options[j].choice) {
                questions[i].options[j].choice = "";
            }
        }

        this.setState({
            questions
        })
    }

    imageQuestionHandler = i => e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let questions = [...this.state.questions];

                questions[i].question.Q = reader.result;

                this.setState({
                    questions
                })
            }
        }
        
        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    imageQuestionOptionHandler = (i, j) => e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let questions = [...this.state.questions];

                questions[i].options[j].choice = reader.result;

                this.setState({
                    questions
                })
            }
        }
        
        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    deleteQuiz = async () => {
        try {
            const response = await this.props.actions.deleteQuiz(this.props.match.params.id);

            if (response) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.quiz', {action: ''}) }),
                        title: this.state.localize('shared.static.success')
                    }
                });

                setTimeout(this.hideToast, 3000);

                setTimeout(() => this.props.history.replace('/quiz'), 3000);
            }
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
    };

    render() {
        const { isFetchingQuiz, startDate, endDate, questions, title, error, classes, subjects, classID, subjectID } = this.state;
        return (
            <div>
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <HeaderBar />

                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <ResultList quizList={this.state.quizList} onClose={this.showModal} show={this.state.showResultModal} />
                </div>
                <div className="create-quiz-list__container">
                    <div className="create-quiz-list__header">
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{this.state.localize('pages.quiz', { action: this.state.localize('shared.static.create')})}</h3>
                        <div className="action-button__wrapper">
                            <Link to={{ pathname: `/quiz` }} >
                                <Button
                                    variant="secondary">
                                    { this.state.localize('shared.buttons.close')}
                                </Button>
                            </Link>
                            <Button
                                className="search-button"
                                variant="danger"
                                onClick={() => this.deleteQuiz()}>
                                { this.state.localize('shared.buttons.delete')}
                            </Button>
                            <Button
                                className="search-button"
                                onClick={() => this.setQuizIDResult()}>
                                { this.state.localize('shared.buttons.result')}
                            </Button>
                            <Link to={{ pathname: `/quiz/update/${this.props.match.params.id}` }} >
                                <Button
                                    className="search-button"
                                    onClick={() => this.updateQuiz()}>
                                    { this.state.localize('shared.buttons.update')}
                                </Button>
                            </Link>
                        </div>
                    </div> {/* Quiz Header closed */}
                    <div className="create-quiz-list__header">
                        <Container fluid={true} style={{ padding: '0px' }}>
                            <Row className="top-options-row">
                                <Col lg={2} md={12} xs={12}>
                                    <select className="form-control form-sm" onChange={this.onSelectClass} value={classID}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                        {classes.map((c) => {
                                            return (
                                                <option value={c._id} key={c._id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                <Col lg={2} md={12} xs={12}>
                                    <select className="form-control form-sm" onChange={this.onSelectSubject} value={subjectID}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                        {subjects.map((s) => {
                                            return (
                                                <option value={s._id} key={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                {/* <Col md={1}>
                                    <h3 style={{fontSize: '26px', fontWeight: '100' }}>Duration</h3>
                                </Col> */}

                                <Col lg={2} md={12} xs={12}>
                                    <Form.Control type="text" value={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} readOnly placeholder={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={startDate}
                                        timeInputLabel="Time:"
                                        placeholderText={startDate ? startDate : this.state.localize('quizComponent.shared.startDate')}
                                        onChange={date => this.handleStartDate(date)}
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                    />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={endDate}
                                        placeholderText={endDate ? endDate : this.state.localize('quizComponent.shared.endDate')}
                                        onChange={date => this.handleEndDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                    />
                                </Col>
                            </Row>
                        </Container>
                        {/* <div className="action2-button__wrapper">
                            <Class default="E" onSelect={this.onSelectClass} />
                            <Subject default={this.state.subjectQuizName } onSelect={this.onSelectSubject} />

                            <h3>Duration</h3>
                            <div>
                                <Form.Control type="number" value={duration} placeholder="Duration in minutes" onChange={this.handleDuration} />
                            </div>
                        </div>
                        <div className="action2-button__wrapper">
                            <DatePicker
                                className="date_picker"
                                selected={startDate}
                                timeInputLabel="Time:"
                                placeholderText={startDate ? startDate : this.state.localize('quizComponent.shared.startDate')}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput

                            />

                            <DatePicker
                                className="date_picker"
                                selected={endDate}
                                placeholderText={endDate ? endDate : this.state.localize('quizComponent.shared.endDate')}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput

                            />
                        </div> */}
                    </div> {/* Quiz Header closed */}
                    <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.title')} value={title} onChange={this.handleTitle} />
                    <Container className="section-question-answer" fluid={true}>
                        {error &&
                            <h5 className="text-danger">
                                {error}
                            </h5>
                        }
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{ this.state.localize('quizComponent.shared.questionSection') } </h3>
                        {questions.map((question, i) => {
                            return (
                                <div className="width-100" key={i}>
                                    <div className="width-100">
                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <hr />
                                            </Col>

                                            <Col xs={12} md={12} lg={12}>
                                                <h4 style={{ fontWeight: '100' }}>
                                                    { this.state.localize('quizComponent.shared.questionNumber', { number: i + 1 }) }
                                                </h4>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {
                                                question.question.type === "img" && question.question.Q &&

                                                <Col xs={3} md={3} lg={3}>
                                                    <Image src={question.question.Q} fluid></Image>
                                                    {/* <img src={question.question.Q} alt="" className="img thumbnail" /> */}
                                                </Col>
                                            }
                                        </Row>

                                        <Row className="margin-top-5 margin-bottom-5">
                                            <Col xs={12} md={6} lg={9}>
                                                {question.question.type === "img" ? <Form.File accept="image/*" id={"question-" + i} onChange={this.imageQuestionHandler(i)} /> : <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.question')} value={question.question.Q} onChange={this.handleQuestion(i)} />}
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                                <select className="form-control form-sm" value={question.question.type} onChange={this.handleQuestionType(i)}>
                                                    <option value="text">{ this.state.localize('quizComponent.shared.text') }</option>
                                                    <option value="img">{ this.state.localize('quizComponent.shared.image') }</option>
                                                </select>
                                            </Col>
                                            <Col xs={12} md={1} lg={1} className="text-align-right">
                                                <Button
                                                    variant="danger"
                                                    onClick={this.deleteQuestion(i)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="margin-top-5 margin-bottom-5">
                                            <Col xs={12} md={12} lg={12}>
                                                <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.description')} value={question.description} onChange={this.handleQuestionDescription(i)} />
                                            </Col>
                                        </Row>
                                    </div>

                                    {question.options.map((option, j) => {
                                        return (
                                            <div key={j}>
                                                <Row>
                                                    <Col lg={12} md={12} xs={1}>
                                                        <hr />
                                                    </Col>
                                                </Row>

                                                {
                                                    option.type === "img" && option.choice &&
                                                    <Row>
                                                        <Col xs={12} md={4} lg={4}>
                                                            <Image src={option.choice} fluid style={{ height: '250px' }}></Image>
                                                            {/* <img src={option.choice} alt="" className="img thumbnail" /> */}
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row className="margin-top-5 margin-bottom-5">
                                                    <Col lg={8} style={{ display: 'flex', paddingRight: '0px' }}>
                                                        {/* <Form.Check type="radio" name={"option-" + i} id={j} checked={option.isCorrect} onChange={this.correctOptionSelected(i, j)} /> */}
                                                        {option.type === "img" ? <Form.File className="question-option" accept="image/*" id={"question-" + i + "-option-" + j} onChange={this.imageQuestionOptionHandler(i, j)} /> : <Form.Control className="question-option" type="text" placeholder={this.state.localize('quizComponent.placeholders.option', { count: j + 1})} value={option.choice} onChange={this.handleQuestionOption(i, j)} />}
                                                    </Col>

                                                    <Col lg={3} sm={10} style={{ paddingRight: '0px' }}>
                                                        <select className="form-control form-sm question-option" onChange={this.handleQuestionOptionType(i, j)}>
                                                            <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                            <option key={"image"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                        </select>
                                                    </Col>

                                                    <Col lg={1} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {
                                                            option.isCorrect ?
                                                                <Button className="question-option"
                                                                    variant="success"
                                                                    style={{ marginRight: '5px' }}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </Button>
                                                                :
                                                                <Button className="question-option"
                                                                    variant="warning"
                                                                    onClick={this.correctOptionSelected(i, j)} style={{ marginRight: '5px' }}>
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </Button>
                                                        }
                                                        <Button className="question-option"
                                                            variant="danger"
                                                            onClick={this.deleteOption(i, j)} style={{ marginRight: '5px' }}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </Col>
                                                    {/* <Col lg={1} md={1} xs={1}>
                                                        <Form.Check type="radio" name={"option-" + i} id={j} checked={option.isCorrect} onChange={this.correctOptionSelected(i, j)} />
                                                    </Col>
                                                    <Col lg={8}>
                                                        {option.type === "img" ? <Form.File accept="image/*" id={"question-" + i + "-option-" + j} onChange={this.imageQuestionOptionHandler(i, j)} /> : <Form.Control type="text" placeholder="Question" value={option.choice} onChange={this.handleQuestionOption(i, j)} />}
                                                    </Col>
                                                    <Col lg={2}>
                                                        <select className="form-control form-sm" value={option.type} onChange={this.handleQuestionOptionType(i, j)}>
                                                            <option value="text">{ this.state.localize('quizComponent.shared.text') }</option>
                                                            <option value="img">{ this.state.localize('quizComponent.shared.image') }</option>
                                                        </select>
                                                    </Col>
                                                    <Col lg={1} className="text-align-right">
                                                        <Button
                                                            variant="danger"
                                                            onClick={this.deleteOption(i, j)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </Col> */}
                                                </Row>

                                                {/* <Row>
                                                    <Col lg={12} md={12} xs={1}>
                                                        <hr />
                                                    </Col>
                                                </Row> */}
                                            </div>
                                        )
                                    })}
                                    <Row>
                                        <Col lg={1}>
                                            {
                                                question.options.length < 4 &&
                                                <Button onClick={this.addOption(i)}> <FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newOption') }</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </Container>
                    {!isFetchingQuiz &&
                        <Container className="add-question">
                            <Button onClick={this.addQuestion}><FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newQuestion') }</Button>
                        </Container>
                    }

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        quizID: state.componentReducer.quizID,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchSelectedQuiz, uploadQuizImage, updateQuiz, deleteQuiz, fetchClasses, fetchSubject, fetchQuizID, fetchQuizResult }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpdateQuiz));