import {
    React, connect, bindActionCreators,
    HeaderBar, Col,
    Button, Row, Container, Form, Link,
    fetchSelectedQuiz, submitQuizAnswer
} from '../index';
import { Toast } from "react-bootstrap";
import './style.scss';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ar';
import i18n from '../../i18n';

class AttemptQuiz extends React.Component {
    state = {
        currentQuestionIndex: 0,
        questions: [],
        attemptedQuestion: null,
        toast: {
            show: false,
            message: null,
            title: null
        },
        quizHaventStarted: false,
        localize: this.props.t
    };

    nextQuestion = async () => {
        if (this.state.questions.length - 1 > this.state.currentQuestionIndex) {
            this.setState({
                currentQuestionIndex: this.state.currentQuestionIndex + 1
            })

            let attemptedQuestion = this.state.attemptedQuestion;

            if (attemptedQuestion) {
                try {
                    const response = await this.props.actions.submitQuizAnswer(attemptedQuestion);

                    if (response) {
                        this.setState({
                            attemptedQuestion: null
                        })
                    }
                } catch (error) {
                    this.setState({ error: error || this.state.localize('shared.static.serverError') });

                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: error.message,
                            title: this.state.localize('shared.static.warning')
                        }
                    });
                    setTimeout(() => {
                        this.hideToast()

                        window.location.href = "/quiz";
                    }, 3000);
                }
            } /* else {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: "Select atleast one option."
                    }
                });

                setTimeout(this.hideToast, 3000);
            } */
        }
    }

    previousQuestion = () => {
        if (this.state.currentQuestionIndex !== 0) {
            this.setState({
                currentQuestionIndex: this.state.currentQuestionIndex - 1
            })
        }
    }

    componentDidMount() {
        this.getQuiz();
    }

    getQuiz = async () => {
        try {
            let quizList = await this.props.actions.fetchSelectedQuiz(this.props.match.params.id);

            this.setState({
                questions: quizList.data.questions,
                title: quizList.data.title,
                subjectID: quizList.data.subject_id,
                teacherID: quizList.data.teacher_id,
                quizID: quizList.data._id,
                start_date: quizList.data.start_date,
                end_date: quizList.data.end_date,
                duration: moment(quizList.data.end_date).locale(i18n.language).from(moment(quizList.data.start_date))
            });
        } catch (error) {
            this.setState({ error: error || this.state.localize('shared.static.serverError') });

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(() => {
                this.hideToast()

                this.props.history.replace('/quiz')
            }, 3000);
        }
    };

    quizAnswerSelected = (questionIndex, optionIndex) => e => {
        e.preventDefault();

        let questions = [...this.state.questions];

        for (let i = 0; i < questions.length; i++) {
            for (let j = 0; j < questions[i].options.length; j++) {
                if (i === questionIndex) {
                    if (questions[i].options[j].hasSelected) {
                        questions[i].options[j].hasSelected = false;
                    }
                }
            }
        }

        questions[questionIndex].options[optionIndex].hasSelected = true;

        this.setState({
            attemptedQuestion: {
                answer: questions[questionIndex].options[optionIndex].choice,
                teacher_id: this.state.teacherID,
                quiz_id: this.state.quizID,
                subject_id: this.state.subjectID,
                question_id: this.state.questions[questionIndex]._id
            },
            questions
        })
    };

    submitQuiz = async () => {
        // need better code for this
        let attemptedQuestion = this.state.attemptedQuestion;

        if (attemptedQuestion) {
            try {
                const response = await this.props.actions.submitQuizAnswer(attemptedQuestion);

                console.log(response)

                if (response) {
                    this.setState({
                        attemptedQuestion: null,
                        toast: {
                            show: true,
                            message: response.message,
                            title: this.state.localize('shared.static.success')
                        }
                    }, () => {
                        setTimeout(() => this.props.history.replace('/quiz'), 3000);
                    })
                }
            } catch (error) {
                this.setState({ error: error || this.state.localize('shared.static.serverError') });

                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: error.message,
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(() => {
                    this.hideToast()

                    this.props.history.replace('/quiz')
                }, 3000);
            }
        } /* else {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: "Select atleast one option."
                }
            });

            setTimeout(this.hideToast, 3000);
        } */
    };

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null
            }
        })
    }

    render() {
        const { currentQuestionIndex, questions, title, duration, quizHaventStarted } = this.state;
        return (
            <div>
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{ this.state.toast.title }</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <HeaderBar />

                <div className="attempt-quiz-list__container">
                    <div className="attempt-quiz-list__header">
                        <h3 style={{ fontWeight: '100', textTransform: 'uppercase' }}>{this.state.localize('quizComponent.shared.attempt')}</h3>
                        <div className="action-button__wrapper">
                            {this.state.localize('quizComponent.shared.remaining')} {duration}
                        </div>
                    </div>
                    {/* <div>
                    </div> */}
                    {
                        questions.length > 0 &&
                        <Container fluid={true} style={{ padding: '0px', marginTop: '10px', marginBottom: '10px' }}>
                            <Row>
                                <Col md={12} lg={12} xs={12}>
                                    <h5 style={{ fontWeight: '100' }}>
                                    {this.state.localize('quizComponent.shared.title')}
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={12} xs={12}>
                                    <Form.Control readOnly type="text" value={title}></Form.Control>
                                </Col>
                            </Row>
                        </Container>
                    }

                    {
                        questions.length > 0 &&
                        <Container fluid={true} style={{ padding: '0px', paddingTop: '5px' }}>
                            {
                                questions[currentQuestionIndex].question.type === "img" ?
                                    <Row>
                                        <Col md={12} lg={12} xs={12}>
                                            <h3 style={{ fontWeight: '100' }}>
                                                Question {currentQuestionIndex + 1}
                                            </h3>
                                        </Col>

                                        <Col md={12} lg={12} xs={12}>
                                            <img src={questions[currentQuestionIndex].question.Q} alt="choice_image" height="200px" width="250px" />
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col md={12} lg={12} xs={12}>
                                            <h3 style={{ fontWeight: '100' }}>
                                                Question {currentQuestionIndex + 1} - {questions[currentQuestionIndex].question.Q}
                                            </h3>
                                        </Col>
                                    </Row>
                            }

                            <Row>
                                <Col md={12} lg={12} xs={12}>
                                    <Form.Control readOnly value={questions[currentQuestionIndex].description}></Form.Control>
                                </Col>
                            </Row>

                            {
                                questions[currentQuestionIndex].options.map((option, j) => {
                                    return (
                                        <Row key={j} style={{ paddingTop: '10px' }}>
                                            <Col md={12} lg={12} xs={12} style={{ display: 'flex' }}>
                                                <Form.Check type="radio" readOnly={quizHaventStarted} checked={option.hasSelected || false} name={questions[currentQuestionIndex]._id} key={questions[currentQuestionIndex]._id} onChange={this.quizAnswerSelected(currentQuestionIndex, j)}></Form.Check>
                                                {
                                                    option.type === "img" ?
                                                        <img src={option.choice} height="200px" width="250px" alt="choice_image" />
                                                        :
                                                        <Form.Label>{option.choice}</Form.Label>
                                                }
                                                <hr />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Container>
                    }

                    <Container fluid={true} style={{ padding: '0px', paddingTop: '5px' }}>
                        {
                            currentQuestionIndex !== 0 &&
                            <Button disabled={quizHaventStarted} onClick={() => this.previousQuestion()} style={{ float: 'left' }}>{this.state.localize('quizComponent.shared.buttons.back')}</Button>
                        }
                        {
                            currentQuestionIndex < questions.length - 1 &&
                            <Button disabled={quizHaventStarted} onClick={() => this.nextQuestion()} style={{ float: 'right' }}>{this.state.localize('quizComponent.shared.buttons.next')}</Button>
                        }
                        {
                            currentQuestionIndex === questions.length - 1 &&
                            <Link to={{ pathname: `quiz/` }}>
                                <Button disabled={quizHaventStarted} onClick={() => this.submitQuiz()} style={{ float: 'right' }}>{this.state.localize('shared.buttons.submit')}</Button>
                            </Link>
                        }
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        // Redux props
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchSelectedQuiz, submitQuizAnswer }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AttemptQuiz));