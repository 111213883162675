import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { fetchAssignmentSubmittedList } from '../components/duck/operations';
import './assignment-list.scss';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

class assignmentList extends React.Component {
    state = {        
        show: false,
        isFetchingAssignmentList: false,
        assignments: [],
        localize: this.props.t
    }

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    componentDidMount() { this.fetchAPI(); }

    fetchAPI = async () => {
        this.setState({ isFetchingAssignmentList: true })
        try {
            let studentAssignmentDetail = await this.props.actions.fetchAssignmentSubmittedList(this.props.assignmentID);
            this.setState({ assignments: studentAssignmentDetail.list })
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingAssignmentList: false })
    }

    render() {
        const { assignments } = this.state
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('assignmentComponent.assignmentSubmissionComponent.title')}</Modal.Title> 
                    </Modal.Header>
                    <Modal.Body className="assignment-submission-list__wrapper">
                        {assignments.length > 0 && assignments.map((assignment) => {
                            return (
                            <div key={assignment._id} className="assignment-submission-row__wrapper">
                                <div className="first_span">
                                    <span> 
                                        <img src={assignment.student.profilePic || 'https://image.flaticon.com/icons/png/512/68/68160.png'} style={{ width: '50px', height: '50px'}} alt="User-profile"/>
                                    </span>
                                    <span>
                                        <div> {assignment.student.name} {' ' + assignment.student.roll_number} </div>
                                        <div> {' ' + moment(assignment.created_at).locale(i18n.language).fromNow()} </div>
                                    </span>
                                </div>
                                <div> <a href={assignment.attachment.url} target="_blank" rel="noopener noreferrer"> {this.state.localize('assignmentComponent.assignmentSubmissionComponent.downloadFile')} </a> </div>
                            </div>)})}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            { this.state.localize('shared.buttons.close') }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* assignmentID: state.componentReducer.assignmentID */
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ 
        fetchAssignmentSubmittedList: fetchAssignmentSubmittedList
       }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(assignmentList));