import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux';
import moment from 'moment';
import 'moment/locale/ar';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { fetchAssignmentComment, addAssignmentComment, deleteAssignmentComment, updateAssignmentComment } from '../components/duck/operations';
import './comment.scss';
import { withTranslation } from 'react-i18next';

import i18n from '../i18n';

class comment extends React.Component {
    state = {
        show: false,
        commentList: [],
        comment: "",
        isFetchingComment: false,
        isPostingComment: false,
        pagination: {
            itemsPerPage: 10,
            totalItems: 0,
            currentPage: 1,
            controlMarkup: []
        },
        commentUserWantToUpdate: null,
        commentUserWantToUpdateIndex: null,
        localize: this.props.t
    }

    closeEditModal = () => { 
        this.setState({
            ...this.state, 
            show: false,
            commentUserWantToUpdate: null,
            commentUserWantToUpdateIndex: null,
            updateCommentText: ''
        })
    };

    onClose = e => { this.onClose && this.onClose(e); };
    handleComment = (event) => { this.setState({ comment: event.target.value }) }
    handleUpdateComment = (event) => { this.setState({ updateCommentText: event.target.value }) }
    componentDidMount() { this.fetchComment(); }

    fetchComment = async () => {
        this.setState({ isFetchingComment: true })
        try {
            let APIComments = await this.props.actions.fetchAssignmentComment(this.props.assignmentID, this.state.pagination.itemsPerPage, this.state.pagination.currentPage);

            let arr = [];

            for (let i = 0; i < Math.ceil(APIComments.count / this.state.pagination.itemsPerPage); i++) {
                arr.push(<Pagination.Item key={i} active={i + 1 === this.state.pagination.currentPage} onClick={() => this.setPaginationPage(i + 1)}>{i + 1}</Pagination.Item>)
            }

            this.setState({
                commentList: APIComments.list,
                pagination: {
                    itemsPerPage: APIComments.limit,
                    totalItems: APIComments.count,
                    currentPage: APIComments.page,
                    controlMarkup: arr
                }
            })
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingComment: false })
    }

    commentSubmitAPI = async () => {
        this.setState({ isPostingComment: true })
        try {
            let response = await this.props.actions.addAssignmentComment(this.props.assignmentID, this.state.comment);
            if (response) {
                await this.fetchComment();
                this.setState({ comment: "" })
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isPostingComment: false })
    }

    setPaginationPage = (i) => {
        let pagination = { ...this.state.pagination };

        pagination.currentPage = i;

        this.setState({
            pagination
        }, () => {
            this.fetchComment();
        })
    }
    changePaginationLimit = (e) => {
        let pagination = { ...this.state.pagination };

        pagination.itemsPerPage = e.target.value;
        pagination.currentPage = 1;

        this.setState({
            pagination
        }, () => {
            this.fetchComment();
        })
    }

    setCommentToBeUpdated = (index) => {
        if(index >= 0) {
            const comment = {...this.state.commentList[index]};

            this.setState({
                show: !this.state.show,
                commentUserWantToUpdate: comment,
                commentUserWantToUpdateIndex: index,
                updateCommentText: comment.comment || ''
            })
        }
    }

    updateComment = async () => {
        if(this.state.commentUserWantToUpdate) {
            const comment = {...this.state.commentUserWantToUpdate};

            const comments = [...this.state.commentList];

            try {
                const response = await this.props.actions.updateAssignmentComment(comment._id, this.props.assignmentID, this.state.updateCommentText);
                
                if(response.success) {
                    comments[this.state.commentUserWantToUpdateIndex].comment = this.state.updateCommentText;

                    this.setState({
                        commentList: comments,
                        updateCommentText: '',
                        show: false
                    })
                }

                if(!response.success) {
                    throw new Error(response.message)
                }

            } catch (error) {
                this.props.onError(error.message || this.state.localize('shared.static.serverError'));
            }
        }
    }

    deleteComment = async (id, index) => {
        try {
            let response = await this.props.actions.deleteAssignmentComment(id);

            if(response.success) {
                let comments = [...this.state.commentList];

                comments.splice(index, 1);

                this.setState({
                    commentList: comments
                })
            }
        } catch(error) {
            throw new Error(error)
        }
    };

    render() {
        const { isPostingComment, isFetchingComment, commentList, pagination } = this.state
        return (
            <div>
                <Container fluid={true}>
                    {
                        commentList.length > 0 && commentList.map((commentList, i) => {
                            return (
                                <Row style={{padding: '5px 0px'}} key={commentList._id}>

                                    <Col md={10} lg={10} xs={10} style={{display: 'flex'}}>
                                        <img src={commentList.info.profilePic || (localStorage.getItem('role') === 'teacher' && commentList.created_by === localStorage.getItem('userID')) ? 'https://img2.pngio.com/avatar-teacher-woman-icon-female-professor-png-512_512.png' : 'https://image.flaticon.com/icons/png/512/68/68160.png'} style={{ width: '50px', height: '50px'}} alt="User-profile"/>
                                        <div style={{ marginLeft: '5px'}}><b>{commentList.info.name + ' '}</b> {moment(commentList.created_at).locale(i18n.language).fromNow()} <br/> {commentList.comment} </div> 
                                    </Col>

                                    <Col md={2} lg={2} xs={2} style={{textAlign: 'right'}}>
                                        {
                                            commentList.created_by === localStorage.getItem('userID') && <Button variant="primary" style={{maxWidth: '40px', margin: '0px 5px'}} onClick={() => this.setCommentToBeUpdated(i)}><FontAwesomeIcon icon={faPenAlt} /></Button>
                                        }
                                        {
                                            localStorage.getItem('role') === 'teacher' ? 
                                            <Button variant="danger" style={{maxWidth: '40px', margin: '0px 5px'}} onClick={() => this.deleteComment(commentList._id, i)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                                            :
                                            commentList.created_by === localStorage.getItem('userID') && <Button variant="danger" style={{maxWidth: '40px', margin: '0px 5px'}} onClick={() => this.deleteComment(commentList._id, i)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                                        }
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Container>
                {/* <div className="comment-list__container">
                    <div className="comments__wrapper">
                        {commentList.length > 0 && commentList.map(commentList => {
                            return (
                            <div className="comment__row" key={commentList._id}>
                                <span className="comment_profilePic"> {<img src={commentList.info.profilePic} alt="No Profile Pic"/>} </span>
                                <span>
                                    <div><b>{commentList.info.name + ' '}</b> {moment(commentList.created_at).fromNow()} </div>
                                    <div>{commentList.comment}</div>
                                </span>
                                <span style={{ width: '100%', padding: '5px', textAlign: 'right'}}>
                                    <Button variant="danger" style={{maxWidth: '40px', margin: '0px 5px'}}><FontAwesomeIcon icon={faPenAlt} /></Button>
                                    <Button variant="primary" style={{maxWidth: '40px', margin: '0px 5px'}} onClick={() => this.deleteComment(commentList._id)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                                </span>
                            </div>)})} 
                    </div>
                </div> */}

                {/* <div className="comment_submit">
                    <input placeholder="Comment" value={this.state.comment} onChange={this.handleComment} type="text" style={{marginRight: '10px', width: '95%', height: '40px'}} />
                    <Button
                        variant="success"
                        onClick={this.commentSubmitAPI}
                        disable={isPostingComment}>
                        Comment
                    </Button>
                </div> */}

                <Container fluid={true} style={{ marginTop: '10px' }}>
                    <Row style={{ padding: '5px 0px' }}>
                        <Col md={10} lg={11} xs={1}>
                            <input placeholder={this.state.localize('assignmentComponent.shared.comments')} value={this.state.comment} onChange={this.handleComment} type="text" style={{ marginRight: '10px', width: '100%', height: '40px' }} />
                        </Col>

                        <Col md={2} lg={1} xs={11} style={{ textAlign: 'right', padding: '0px', margin: '0px' }}>
                            <Button
                                style={{ width: '100%' }}
                                variant="primary"
                                disabled={isPostingComment}
                                onClick={this.commentSubmitAPI}>
                                {
                                    isPostingComment && <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faCircleNotch} spin />
                                }
                                    { this.state.localize('assignmentComponent.shared.comments') }
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ padding: '5px 0px' }}>
                        {
                            pagination.totalItems > 0 &&
                            <Col md={4} lg={4} xs={12}>
                                <select className="form-control form-sm" onChange={this.changePaginationLimit} style={{ maxWidth: '250px' }} disabled={isFetchingComment}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </Col>
                        }

                        <Col md={8} lg={8} xs={12} style={{ padding: '0px' }}>
                            <Pagination style={{ float: 'right' }}>
                                {
                                    pagination.controlMarkup
                                }
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Modal size="lg" show={this.state.show} animation={true}>
                                <Modal.Header>
                                    <Modal.Title>{ this.state.localize('assignmentComponent.shared.editComment') }</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="show-grid">
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <Modal.Body>
                                                    <label> { this.state.localize('assignmentComponent.shared.comments') + ':' } </label>
                                                    <textarea rows="1" value={this.state.updateCommentText} onChange={this.handleUpdateComment}></textarea>
                                                </Modal.Body>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={this.closeEditModal}
                                        disable={isPostingComment.toString()}>
                                        {this.state.localize('shared.buttons.close')}
                                    </Button>

                                    <Button
                                        variant="success"
                                        onClick={this.updateComment}
                                        disable={isPostingComment.toString()}>
                                        {!isPostingComment ? <FontAwesomeIcon icon={faPenAlt} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                        {!isPostingComment ? (' ' + this.state.localize('shared.buttons.update')) : (' ' + this.state.localize('shared.buttons.wait'))}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* assignmentID: state.componentReducer.assignmentID */
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchAssignmentComment: fetchAssignmentComment,
            addAssignmentComment: addAssignmentComment,
            deleteAssignmentComment: deleteAssignmentComment,
            updateAssignmentComment: updateAssignmentComment
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(comment));