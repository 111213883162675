import {
    React, connect, bindActionCreators,
    Modal, Button, fetchQuizResult, FontAwesomeIcon, faCircleNotch, moment
} from '../index';
import './style.scss';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ar';
import i18n from '../../i18n';

class result extends React.Component {
    state = {
        show: false,
        results: [],
        isFetchingResultList: false,
        localize: this.props.t
    }

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    renderResultList(quizResult) {
        return <div className="result-list__wrapper">
            <div className="result-list__heading">
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.title')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.studentName')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.class')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.subject')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.submittedDate')}</b>
                {/* <b>Date</b> */}
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.score')}</b>
            </div>
            {
                quizResult.map((result, i) => {
                    return (
                        <div className="result-list__row" key={i}>
                            <span>{result.title}</span>
                            <span>{result.studentName}</span>
                            <span>{result.className}</span>
                            <span>{result.subjectName}</span>
                            <span>{ moment.utc(result.quiz_submitted).locale(i18n.language).format("DD/MM/YYYY") }</span>
                            {/* <span>{ moment.utc(result.created_at).format("DD/MM/YYYY") }</span> */}
                            <span>{result.correct_answer} / {result.total_questions}</span>
                        </div>
                    )
                })
            }
        </div>
    }

    render() {
        const { quizList } = this.props;
        return (
            <div>
                <Modal size={"lg"} show={this.props.show}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('quizComponent.resultModal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !quizList ?
                                <div className="text-xl-center">
                                    <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                                </div> :
                                this.renderResultList(quizList.list)
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            {this.state.localize('shared.buttons.save', { type: ''})}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        quizID: state.componentReducer.quizID,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchQuizResult }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(result));