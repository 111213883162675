import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClasses } from '../duck/operations';
import { withTranslation } from 'react-i18next';

class classList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        dropDownValue: this.props.t('shared.texts.select', {type: this.props.t('shared.static.class')}), 
        classPending: false,
        classes: [],
        isFetchingClasses: false,
        selectedClass: this.props.default
    }
}
    changeValue(text) { this.setState({dropDownValue: text}) }
    componentDidMount() { this.props.actions.fetchClasses() }

    render() {
        const { classList } = this.props;
        const { dropDownValue } = this.state;
        return (
            <div>
                { classList && classList.length === 0
                    ? 
                    <DropdownButton 
                        disabled 
                        id="dropdown-basic-button" 
                        title={dropDownValue}>
                    </DropdownButton> 
                    : 
                    <DropdownButton 
                        id="dropdown-basic-button" 
                        onSelect={this.props.onSelect}
                        title={dropDownValue}>
                            {classList && classList.map((classList) =>
                            <Dropdown.Item 
                                id={classList._id} 
                                key={classList._id} 
                                eventKey={classList._id} 
                                onClick={() => this.changeValue(classList.name)}> 
                                    {classList.name}
                            </Dropdown.Item> )}
                    </DropdownButton>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        classList: state.componentReducer.classList    
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ fetchClasses: fetchClasses }, dispatch)
    };
}  

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(classList));