import "react-datepicker/dist/react-datepicker.css";
import {
    React, connect, bindActionCreators,
    DatePicker, Button, Link,
    HeaderBar, Form,
    Row, Col, Container, ResultList, fetchQuizID, fetchQuizResult, fetchQuizById, deleteQuiz, fetchClasses, fetchSubject,
    FontAwesomeIcon, faCheck, faTimes, moment
} from '../index';
import { Toast } from "react-bootstrap";
import './style.scss';
import { withTranslation } from "react-i18next";

class ViewQuiz extends React.Component {
    state = {
        className: null,
        subjectName: null,
        dropDownValue: 'Text',
        title: '',
        questions: [],
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        classes: [],
        subjects: [],
        toast: {
            show: false,
            message: null,
            title: null
        },
        localize: this.props.t
    }

    componentDidMount() {
        this.fetchQuiz();
    }

    onSelectClass = (e) => {
        if (e.target.value !== "Select One") {
            this.setState({ classID: e.target.value });
        }
    }
    onSelectSubject = (e) => {
        if (e.target.value !== "Select One") {
            this.setState({ subjectID: e.target.value });
        }
    }

    async getClasses() {
        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes
            })
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    }

    fetchQuiz = async () => {
        this.setState({ isFetchingQuiz: true })
        try {
            let quizList = await this.props.actions.fetchQuizById(this.props.match.params.id);
            this.setState({
                questions: quizList.data.questions,
                startDate: moment(quizList.data.start_date).valueOf(),
                endDate: moment(quizList.data.end_date).valueOf(),
                title: quizList.data.title,
                className: quizList.data.className,
                classID: quizList.data.class_id,
                subjectName: quizList.data.subjectName,
                subjectID: quizList.data.subject_id,
            });

            this.getClasses();
            this.getSubjects();
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(() => {
                this.hideToast();

                this.props.history.replace('/quiz');
            }, 3000);
        }
        this.setState({ isFetchingQuiz: false })
    }

    showModal = () => { this.setState({ showResultModal: !this.state.showResultModal }); };

    setQuizIDResult = async () => {
        this.showModal();
        let quiz = await this.props.actions.fetchQuizID(this.props.match.params.id);
        if (quiz.payload) {
            let result = await this.props.actions.fetchQuizResult(quiz.payload);
            this.setState({ quizList: result })
        }
    }

    deleteQuiz = async () => {
        try {
            const response = await this.props.actions.deleteQuiz(this.props.match.params.id);

            if (response) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.quiz', {action: ''}) }),
                        title: this.state.localize('shared.static.success')
                    }
                });

                setTimeout(this.hideToast, 3000);

                setTimeout(() => this.props.history.replace('/quiz'), 3000);
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
    };

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            }
        })
    }

    render() {
        const { questions, startDate, endDate, title, classes, subjects, classID, subjectID } = this.state;
        return (
            <div>
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{ this.state.toast.title }</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <HeaderBar />
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <ResultList quizList={this.state.quizList} onClose={this.showModal} show={this.state.showResultModal} />
                </div>
                <div className="create-quiz-list__container">
                    <div className="create-quiz-list__header">
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{this.state.localize('pages.quiz', { actions: 'View'})}</h3>
                        <div className="action-button__wrapper">
                            <Link to={{ pathname: `/quiz` }} >
                                <Button
                                    variant="secondary">
                                    { this.state.localize('shared.buttons.close')}
                                </Button>
                            </Link>
                            <Button
                                className="search-button"
                                variant="danger"
                                onClick={() => this.deleteQuiz()}>
                                { this.state.localize('shared.buttons.delete')}
                            </Button>
                            <Button
                                className="search-button"
                                onClick={() => this.setQuizIDResult()}>
                                { this.state.localize('shared.buttons.result')}
                            </Button>
                            <Link to={{ pathname: `/quiz/update/${this.props.match.params.id}` }} >
                                <Button
                                    className="search-button">
                                    { this.state.localize('shared.buttons.update')}
                                </Button>
                            </Link>
                        </div>
                    </div> {/* Quiz Header closed */}
                    <div className="create-quiz-list__header">
                        <Container fluid={true} style={{ padding: '0px' }}>
                            <Row className="top-options-row">
                                <Col lg={2} md={12} xs={12}>
                                    <select className="form-control form-sm" onChange={this.onSelectClass} value={classID} disabled={true} readOnly>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                        {classes.map((c) => {
                                            return (
                                                <option value={c._id} key={c._id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                <Col lg={2} md={12} xs={12}>
                                    <select className="form-control form-sm" onChange={this.onSelectSubject} value={subjectID} disabled={true} readOnly>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                        {subjects.map((s) => {
                                            return (
                                                <option value={s._id} key={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                {/* <Col md={1}>
                                    <h3 style={{fontSize: '26px', fontWeight: '100' }}>Duration</h3>
                                </Col> */}
                                <Col lg={2} md={12} xs={12}>
                                    <Form.Control type="text" value={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} readOnly placeholder={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={startDate}
                                        timeInputLabel="Time:"
                                        placeholderText={startDate ? startDate : this.state.localize('quizComponent.shared.startDate')}
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                        readOnly={true}
                                    />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={endDate}
                                        placeholderText={endDate ? endDate : this.state.localize('quizComponent.shared.endDate')}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        {/* <div className="action2-button__wrapper">
                            <Class default={className} />
                            <Subject default={subjectName} /> 
                            <h3>Duration</h3>
                            <div>
                                <Form.Control type="number" value={duration} readOnly placeholder="Duration in minutes" />
                            </div>
                        </div> */}
                        {/* <div className="action2-button__wrapper">
                            <DatePicker
                                className="date_picker"
                                selected={startDate}
                                timeInputLabel="Time:"
                                placeholderText={startDate ? startDate : this.state.localize'quizComponent.shared.startDate')}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                readOnly={true}
                            />

                            <DatePicker
                                className="date_picker"
                                selected={endDate}
                                placeholderText={endDate ? endDate : this.state.localize('quizComponent.shared.endDate')}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                readOnly={true}
                            />
                        </div> */}
                    </div> {/* Quiz Header closed */}
                    <Form.Control type="text" placeholder="Title" value={title} readOnly />
                    <Container className="section-question-answer" fluid={true}>
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{ this.state.localize('quizComponent.shared.questionSection') } </h3>
                        {questions.map((question, i) => {
                            return (
                                <div className="width-100" key={i}>
                                    <div className="width-100">
                                        <Row>
                                            <Col lg={12} md={12} xs={1}>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <h4 style={{ fontWeight: '100' }}>
                                                    { this.state.localize('quizComponent.shared.questionNumber', { number: i + 1 }) }
                                                </h4>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {
                                                question.question.type === "img" && question.question.Q &&

                                                <Col xs={12} md={4} lg={3}>
                                                    <img src={question.question.Q} alt="" className="img thumbnail"  style={{maxWidth: '220px', maxHeight: '200px'}}/>
                                                </Col>
                                            }
                                        </Row>

                                        <Row className="margin-top-5 margin-bottom-5">
                                            <Col sm={12} md={8} lg={10}>
                                                {question.question.type === "img" ? <Form.File disabled={true} accept="image/*" id={"question-" + i} /> : <Form.Control type="text" placeholder="Question" value={question.question.Q} readOnly />}
                                            </Col>
                                            {/* <Col xs={12} md={1} lg={1} className="text-align-right">
                                                    <Button
                                                        variant="danger">
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </Button>
                                                </Col> */}
                                            <Col xs={12} md={4} lg={2}>
                                                <select className="form-control form-sm" value={question.type} disabled={true}>
                                                    <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                    <option key={"img"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row className="margin-top-5 margin-bottom-5">
                                            <Col xs={12} md={12} lg={12}>
                                                <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.description')} value={question.description} readOnly />
                                            </Col>
                                        </Row>
                                    </div>

                                    {question.options.map((option, j) => {
                                        return (
                                            <div key={j}>
                                                <Row>
                                                    <Col lg={12} md={12} xs={1}>
                                                        <hr />
                                                    </Col>
                                                </Row>

                                                {
                                                    option.type === "img" && option.choice &&
                                                    <Row>
                                                        <Col xs={12} md={4} lg={3}>
                                                            <img src={option.choice} alt="" className="img thumbnail" style={{maxWidth: '220px', maxHeight: '200px'}}/>
                                                        </Col>
                                                    </Row>
                                                }

                                                <Row className="margin-top-5 margin-bottom-5">
                                                    {/* <Col lg={1} md={1} xs={1}>
                                                        <Form.Check type="radio" name="formHorizontalRadios" id={j} checked={option.isCorrect} readOnly />
                                                    </Col> */}
                                                    <Col lg={8}>
                                                        {option.type === "img" ? <Form.File className="question-option" disabled={true} accept="image/*" id={"question-" + i + "-option-" + j} /> : <Form.Control className="question-option" type="text" placeholder="Question" value={option.choice} readOnly />}
                                                    </Col>
                                                    <Col lg={3} sm={10}>
                                                        <select className="form-control form-sm" value={option.type} disabled={true}>
                                                            <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                            <option key={"img"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                        </select>
                                                    </Col>
                                                    <Col lg={1} sm={2} style={{ textAlign: 'right' }}>
                                                        {
                                                            option.isCorrect ?
                                                                <Button className="question-option"
                                                                    variant="success">
                                                                    <FontAwesomeIcon icon={faCheck} disabled={true} />
                                                                </Button>
                                                                :
                                                                <Button className="question-option"
                                                                    variant="warning">
                                                                    <FontAwesomeIcon icon={faTimes} disabled={true} />
                                                                </Button>
                                                        }
                                                    </Col>
                                                    {/* <Col lg={1} className="text-align-right">
                                                        <Button
                                                            variant="danger">
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </Col> */}
                                                </Row>

                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </Container>
                    {/* <Container className="add-question">
                        <Button onClick={this.addQuestion}><FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newQuestion') }</Button>
                    </Container> */}
                </div>
            </div >
        )

    }

}

function mapStateToProps(state) {
    return {
        quizID: state.componentReducer.quizID
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchQuizID, fetchQuizResult, fetchQuizById, deleteQuiz, fetchClasses, fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ViewQuiz));