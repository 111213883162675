import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import translationEN from '../src/locales/en/translation.json';
import translationAR from '../src/locales/ar/translation.json';

const resources = {
    en: {
        translation: translationEN
      },
    ar: {
      translation: translationAR
    }
}

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    resources,
    debug: false,
    react: {
        useSuspense: false
    },
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;