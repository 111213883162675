import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {fetchSubject} from '../duck/operations';

class Subject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownValue: "Any Subject",
            subjects: [],
            isFetchingSubject: false,
    }
}

    changeValue(text) { this.setState({dropDownValue: text})}
    
    handleAPI = async () => {
        this.setState({ isFetchingSubject: true })
        try {
            let class_id;
            console.log(this.props.default);
            if( localStorage.getItem('role') === 'teacher' ){ class_id = this.props.default }
            else if( localStorage.getItem('role') === 'student' ){ class_id = localStorage.getItem('classID'); }
            if(class_id) {
                let subjectfromAPI = await this.props.actions.fetchSubject(class_id);
                if(subjectfromAPI.length > 0){
                    this.setState({ subjects: subjectfromAPI });
                }
            }
        }
        catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingSubject: false })
    }
    render() {
        const {isFetchingSubject, subjects, dropDownValue} = this.state;
        return (
            <div>
                {subjects.length === 0 ?
                    <DropdownButton 
                        onClick={this.handleAPI} 
                        title={dropDownValue}
                        id="dropdown-basic-button">
                    </DropdownButton> :            
                    <DropdownButton 
                        id="dropdown-basic-button" 
                        title={dropDownValue}
                        disabled = {isFetchingSubject}
                        onSelect={this.props.onSelect}
                        onClick={this.handleAPI}>
                        {subjects && subjects.map((subject) =>
                            <Dropdown.Item 
                                key={subject._id}
                                id={subject._id} 
                                eventKey={subject._id}
                                onClick={() => this.changeValue(subject.title)}>
                                    {subject.title}
                            </Dropdown.Item>)}
                    </DropdownButton>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subjectList: state.componentReducer.subjectList
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ 
        fetchSubject: fetchSubject
    }, dispatch)
    };
}  

export default connect(mapStateToProps, mapDispatchToProps)(Subject);