import {
    React, connect, bindActionCreators,
    FontAwesomeIcon, faCircleNotch, faSearch, faPlus,
    Link, Button, moment,
    Can,
    fetchQuiz, deleteQuiz,
    HeaderBar, fetchQuizID, fetchQuizResult, fetchClasses, fetchSubject,
    Container, Row, Col, Pagination
} from './index';
import { Toast } from "react-bootstrap";
import './style.scss';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

class QuizList extends React.Component {
    state = {
        show: false,
        quizzes: [],
        isFetchingQuiz: false,
        class: null, subject: null,
        classes: [], subjects: [],
        title: null, quizID: null, quizList: [],
        isTeacher: false,
        toast: {
            show: false,
            message: null,
            title: null
        },
        pagination: {
            itemsPerPage: 10,
            totalItems: 0,
            currentPage: 1,
            controlMarkup: []
        },
        messageBox: this.props.t('quizComponent.static.selectFilter'),
        localize: this.props.t
    }
    showModal = () => { this.setState({ show: !this.state.show }); };
    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ class: e.target.value, isFetchingQuiz: true }, () => {
                    this.getSubjects()
                });
            }

        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isFetchingQuiz: false
        })
    }

    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subject: e.target.value });
            }
        }
    }
    setQuizIDResult = async (quiz_id) => {
        this.showModal();
        let quiz = await this.props.actions.fetchQuizID(quiz_id);
        if (quiz.payload) {
            let result = await this.props.actions.fetchQuizResult(quiz.payload);
            this.setState({ quizList: result })
        }
    }
    setQuizID = async (quiz_id) => {
        let payload = await this.props.actions.fetchQuizID(quiz_id);
        console.log({ payload });
    }
    handleTitle = (event) => { this.setState({ title: event.target.value }); }
    setPaginationPage = (i) => {
        let pagination = { ...this.state.pagination };

        pagination.currentPage = i;

        this.setState({
            pagination
        }, () => {
            this.fetchQuiz();
        })
    }
    changePaginationLimit = (e) => {
        let pagination = { ...this.state.pagination };

        pagination.itemsPerPage = e.target.value;

        this.setState({
            pagination
        }, () => {
            this.fetchQuiz();
        })
    }
    fetchQuiz = async () => {
        this.setState({ isFetchingQuiz: true })
        try {
            let quizList = await this.props.actions.fetchQuiz(this.state.title, this.state.class, this.state.subject, this.state.pagination.currentPage, this.state.pagination.itemsPerPage);

            let arr = [];

            for (let i = 0; i < Math.ceil(quizList.count / this.state.pagination.itemsPerPage); i++) {
                arr.push(<Pagination.Item key={i} active={i + 1 === this.state.pagination.currentPage} onClick={() => this.setPaginationPage(i + 1)}>{i + 1}</Pagination.Item>)
            }

            this.setState({
                quizzes: quizList.list,
                pagination: {
                    itemsPerPage: quizList.limit,
                    totalItems: quizList.count,
                    currentPage: quizList.page,
                    controlMarkup: arr
                }
            })

            if(quizList.list.length <= 0) {
                this.setState({
                    messageBox: this.state.localize('quizComponent.static.noQuiz')
                })
            } 
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError'),
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
        this.setState({ isFetchingQuiz: false })
    }

    handleDeleteQuiz = (quiz_id) => {
        try {
            let deleteQuiz = this.props.actions.deleteQuiz(quiz_id);
            if (deleteQuiz) {
                this.props.handleMessage(this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.quiz', {action: ''}) }), this.state.localize('shared.static.success'))

                this.fetchQuiz();
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    };

    componentDidMount() {
        this.setState({
            isTeacher: localStorage.getItem('role') === "student" ? false : true
        }, () => {
            if (!this.state.isTeacher) {
                this.getSubjects();
            } else {
                this.getClasses();
            }
        })
    }

    async getClasses() {
        this.setState({
            isFetchingQuiz: true
        })

        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes,
                isFetchingQuiz: false
            })
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                },
                isFetchingQuiz: false
            });

            setTimeout(this.hideToast, 3000);
        }

        this.setState({
            isFetchingQuiz: false
        })
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.class; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects, isFetchingQuiz: false });
                }
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }, 
                isFetchingQuiz: false
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    renderQuizzesList(quizzes) {
        if (quizzes.length < 1) {
            return <div className="list-container-message-box"> <h3> { this.state.localize('quizComponent.static.selectFilter') } </h3> </div>
        }

        // Title Class Subject Date Duration - Edit Delete Attempt

        return (
            <div className="quiz-list__wrapper">
                <div className="quiz-list__heading">
                    <b>{this.state.localize('quizComponent.tableHeadings.title')}</b>
                    <Can I='delete' a='Quiz'>
                        <b>{this.state.localize('quizComponent.tableHeadings.class')}</b>
                    </Can>
                    <b>{this.state.localize('quizComponent.tableHeadings.subject')}</b>
                    <Can I='read' a='Quiz'>
                        <b>{this.state.localize('quizComponent.tableHeadings.duration')}</b>
                    </Can>
                    <b>{this.state.localize('quizComponent.tableHeadings.startTime')}</b>
                    <b>{this.state.localize('quizComponent.tableHeadings.endTime')}</b>
                    <Can I='read' a='Quiz'>
                        <b className="text-right"></b>
                    </Can>
                </div>
                {quizzes && quizzes.map(quiz => {
                    return (
                        this.state.isTeacher ?
                            <Link to={{ pathname: `quiz/view/${quiz._id}` }} style={{ color: 'black' }} key={quiz._id}>
                                <div className="quiz-list__row">
                                    <span>{quiz.title}</span>
                                    <Can I='delete' a='Quiz'>
                                        <span>{quiz.className}</span>
                                    </Can>
                                    <span>{quiz.subjectName}</span>
                                    <Can I='read' a='Quiz'>
                                        <span>{quiz.duration} - minutes</span>
                                    </Can>
                                    <span>
                                        {moment(quiz.start_date).locale(i18n.language).format("DD/MM/YYYY hh:mm A")}
                                    </span>
                                    <span>
                                        {moment(quiz.end_date).locale(i18n.language).format("DD/MM/YYYY hh:mm A")}
                                    </span>
                                    <Can I='read' a='Quiz'>
                                        <span className="text-right" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            {/* <ResultList quizList={this.state.quizList} onClose={this.showModal} show={this.state.show} />
                            <Can I='delete' a='Quiz'>
                                <Button
                                    // Modal for Result
                                    onClick={() => this.setQuizIDResult(quiz._id)}
                                    variant="info">
                                    Result
                            </Button>
                                <Link to={{ pathname: `/quiz/update` }} >
                                    <Button
                                        // Selected ID
                                        onClick={() => this.setQuizIDUpdate(quiz._id)}
                                        variant="light">
                                        <FontAwesomeIcon icon={faPencilAlt} /> Edit
                                </Button>
                                </Link>
                                <Button
                                    variant="danger"
                                    value="delete"
                                    onClick={() => this.handleDeleteQuiz(quiz._id)}>
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </Button>
                            </Can> */}

                                            <Link to={{ pathname: `/quiz/attempt/${quiz._id}` }} >
                                                <Button
                                                    onClick={() => this.setQuizID(quiz._id)}
                                                    variant="outline-success">
                                                    {this.state.localize('pages.quiz', { action: this.state.localize('shared.static.attempt') })}
                                    </Button>
                                            </Link>
                                        </span>
                                    </Can>
                                </div>
                            </Link>
                            :
                            <div className="quiz-list__row" key={quiz._id}>
                                <span>{quiz.title}</span>
                                <Can I='delete' a='Quiz'>
                                    <span>{quiz.className}</span>
                                </Can>
                                <span>{quiz.subjectName}</span>
                                <Can I='read' a='Quiz'>
                                    <span>{quiz.duration} - minutes</span>
                                </Can>
                                <span>
                                    {moment(quiz.start_date).locale(i18n.language).format("DD/MM/YYYY hh:mm A")}
                                </span>
                                <span>
                                    {moment(quiz.end_date).locale(i18n.language).format("DD/MM/YYYY hh:mm A")}
                                </span>
                                <Can I='read' a='Quiz'>
                                    <span className="text-right" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        {/* <ResultList quizList={this.state.quizList} onClose={this.showModal} show={this.state.show} />
                            <Can I='delete' a='Quiz'>
                                <Button
                                    // Modal for Result
                                    onClick={() => this.setQuizIDResult(quiz._id)}
                                    variant="info">
                                    Result
                            </Button>
                                <Link to={{ pathname: `/quiz/update` }} >
                                    <Button
                                        // Selected ID
                                        onClick={() => this.setQuizIDUpdate(quiz._id)}
                                        variant="light">
                                        <FontAwesomeIcon icon={faPencilAlt} /> Edit
                                </Button>
                                </Link>
                                <Button
                                    variant="danger"
                                    value="delete"
                                    onClick={() => this.handleDeleteQuiz(quiz._id)}>
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </Button>
                            </Can> */}

                                        <Link to={{ pathname: `/quiz/attempt/${quiz._id}` }} >
                                            <Button
                                                onClick={() => this.setQuizID(quiz._id)}
                                                variant="outline-success" className="attempt-button">
                                                {this.state.localize('pages.quiz', { action: this.state.localize('shared.static.attempt')})}
                                                {/* {
                                                    !quiz.status ? "Attempt Quiz" : quiz.status === "attempt" ? "Continue Quiz" : "Reattempt Quiz"
                                                } */}
                                    </Button>
                                        </Link>
                                    </span>
                                </Can>
                            </div>
                    )
                })}
            </div>
        )
    }


    render() {
        const { isFetchingQuiz, quizzes, classes, subjects, pagination } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="quiz-list__container">
                    <div className="quiz-list__header">
                        <h3>{this.state.localize('pages.quiz', { action: ''})}</h3>
                        <div className="action-button__wrapper">
                            <Can I='read' a='Class'>
                                {/* <Class default="" onSelect={this.onSelectClass} /> */}
                                <div>
                                    <select className="form-control form-sm" disabled={isFetchingQuiz} onChange={this.onSelectClass}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                        {classes.map((c) => {
                                            return (
                                                <option value={c._id} key={c._id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Can>
                            {/* <Subject default={this.state.class} onSelect={this.onSelectSubject} /> */}
                            <div>
                                <select className="form-control form-sm" disabled={isFetchingQuiz} onChange={this.onSelectSubject}>
                                    <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                    {subjects.map((s) => {
                                        return (
                                            <option value={s._id} key={s._id}>{s.title}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {/* <input type="text" placeholder="Title" onChange={this.handleTitle} /> */}
                            <Button
                                className="search-button"
                                onClick={this.fetchQuiz}
                                disabled={isFetchingQuiz}>
                                {!isFetchingQuiz ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingQuiz ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                            <Can I='create' a='Quiz'>
                                <Link to={{ pathname: `/quiz/create` }} >
                                    <Button
                                        className="search-button">
                                        <FontAwesomeIcon icon={faPlus} /> {this.state.localize('pages.quiz', { action: ''})}
                                    </Button>
                                </Link>
                            </Can>
                            <Can I='read' a='Quiz'>
                                <Link to={{ pathname: `/quiz/result` }} >
                                    <Button
                                        className="search-button"> {this.state.localize('quizComponent.buttons.results')}
                                    </Button>
                                </Link>
                            </Can>
                        </div>
                    </div> {/* Quiz Header closed */}

                    {
                        isFetchingQuiz ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> : this.renderQuizzesList(quizzes)
                    }
                </div>

                <Container fluid={true}>
                    <Row>
                        {
                            pagination.totalItems > 0 &&
                            <Col md={4} lg={4} xs={12}>
                                <select className="form-control form-sm" onChange={this.changePaginationLimit} style={{maxWidth: '250px'}}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </Col>
                        }

                        <Col md={8} lg={8} xs={12}>
                            <Pagination style={{float: 'right'}}>
                                {
                                    pagination.controlMarkup
                                }
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps() {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchQuiz, deleteQuiz, fetchQuizID, fetchQuizResult, fetchClasses, fetchSubject }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QuizList));