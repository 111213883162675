import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from './App';
import './index.scss';
import store from './root-store/store';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './i18n';

Sentry.init({
  dsn: "https://1a1606ca009247e9bac163c8618c2074@o575329.ingest.sentry.io/5727371",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
//import { createBrowserHistory } from "history";
//const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);