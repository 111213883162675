import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;


export async function apiService(method, uri, data, headers = null, params = null) {
    let query = {
        method: method,
        url: uri ? baseUrl + uri : baseUrl
    },
    token = localStorage.getItem('x_token'),
    x_username = localStorage.getItem('x_user'),
    schoolID = localStorage.getItem('schoolID')

    if (headers !== null) {
        query.headers = headers;
    }
    
    if(token && x_username && schoolID){
        if(!query.headers){
            query.headers = {};
        }
        query.headers['x-access-token'] = token
        query.headers['x-access-username-a'] = x_username
    }
    
    if (params !== null) {
        query.params = params;
    }

    if (method === 'post' || method === 'put' || method === 'delete' || method === 'patch') {
        query.data = data;
    }
    return axios(query);
}