import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { uploadImage, assignmentSubmission } from '../components/duck/operations';
import { withTranslation } from 'react-i18next';

class assignment extends React.Component {
    state = {        
        show: false,
        title: "",
        description: "",
        img: [],
        isPostingAssignment: false,    
        localize: this.props.t
    }

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    handleTitle = (event) => { this.setState({title: event.target.value}) }
    handleDescription = (event) => { this.setState({description: event.target.value}) }
    handleImage = (event) => { this.setState({img : event.target.files[0]}) }

    assignmentSubmissionAPI = async () => {
        this.setState({ isPostingAssignment: true })
        try {
            let image = await this.props.actions.uploadImage(this.state.img);
            let studentAssignment = await this.props.actions.assignmentSubmission(this.props.assignmentID, this.state.title, this.state.description, image.data);
            if(studentAssignment){ 
                this.props.showToastError('Assignment submitted succesfully', 'Success');

                this.onClose();
                this.props.onUpdate();
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isPostingAssignment: false })
    }

    render() {
        const { isPostingAssignment } = this.state
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('assignmentComponent.assignmentSubmissionComponent.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label> {this.state.localize('assignmentComponent.assignmentSubmissionComponent.assignmentTitle')} </label>
                                        <textarea rows="1" onChange={this.handleTitle}></textarea>
                                        <label> {this.state.localize('assignmentComponent.assignmentSubmissionComponent.assignmentDescription')} </label>
                                        <textarea rows="1" onChange={this.handleDescription}></textarea>
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label>{this.state.localize('assignmentComponent.assignmentSubmissionComponent.selectFiles')}
                                            <input type="file" onChange={this.handleImage}/>
                                        </label>
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            {this.state.localize('shared.buttons.close', { type: ''})}
                        </Button>

                        <Button
                            variant="success"
                            onClick={this.assignmentSubmissionAPI}
                            disabled={isPostingAssignment}>
                            {!isPostingAssignment ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />} 
                            {!isPostingAssignment ? (' ' + this.state.localize('shared.buttons.submit')) : (' ' + this.state.localize('shared.buttons.wait'))} 
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* assignmentID: state.componentReducer.assignmentID, */
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ 
        assignmentSubmission: assignmentSubmission,
        uploadImage: uploadImage }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(assignment));