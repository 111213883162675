import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ButtonGroup, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons'
import HeaderBar from '../components/HeaderBar';
import Date from '../components/date';
import Can from '../config/Can';
import { fetchAttendance, markAttendance, fetchClasses, fetchSubject, fetchClassesByAttendance } from '../components/duck/operations';
import './style.scss';
import { Toast } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';

class AttendanceList extends React.Component {
    state = {
        messageBox: this.props.t('attendanceComponent.static.selectFilter'),
        isFetchingAttendance: false,
        isMarkingAttendance: false,
        attendances: [],
        error: null,
        class: null, subject: null,
        classes: [], subjects: [],
        student: [{
            studentID: null,
            studentStatus: null
        }],
        toast: {
            show: false,
            message: null,
            title: null
        },
        localize: this.props.t,
        isSchool: false,
        chart: {
            have_data: false,
            show: false,
            object: {
                dataPresent: false,
                labels: ['Present', 'Absent', 'Late', 'Leave'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        }
    }

    componentDidMount() {
        let isTeacher = localStorage.getItem('role') === "student" ? false : true
        let isSchool = localStorage.getItem('schoolType') === "school" ? true : false;

        this.setState({
            isFetchingAttendance: true,
            isSchool
        })

        if (!isTeacher) {
            this.getSubjects();
        } else {
            this.getClasses();
        }
    }

    async getClasses() {
        try {
            const classes = await this.props.actions.fetchClassesByAttendance();

            this.setState({
                classes: this.state.isSchool ? classes.classTeacher : classes.list,
                isFetchingAttendance: false
            })
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                },
                isFetchingAttendance: false
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.class; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects, isFetchingAttendance: false });
                }
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }, isFetchingAttendance: false
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                if (!this.state.isSchool) {
                    this.setState({ class: e.target.value, attendances: [], isFetchingAttendance: true }, () => {
                        this.getSubjects()
                    });
                } else {
                    this.setState({ class: e.target.value, attendances: [] });
                }
            }

        }
    }
    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subject: e.target.value });
            }
        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            }
        })
    }

    //Get Student List from server
    fetchAttendance = async () => {
        if (this.state.attendances.length === 0) {
            this.setState({ isFetchingAttendance: true })
        }
        try {

            if (!this.props.date) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.properDate') }),
                        title: this.state.localize('shared.static.warning')
                    },
                    isFetchingAttendance: false
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            let classID;
            if (localStorage.getItem('role') === 'teacher') {
                if (this.state.class === null) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('shared.texts.selectOne', { type: this.state.localize('shared.static.class') }),
                            title: this.state.localize('shared.static.warning')
                        },
                        isFetchingAttendance: false
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }

                classID = this.state.class;
            }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (!this.state.isSchool) {
                if (this.state.subject === null) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('shared.texts.selectOne', { type: this.state.localize('shared.static.subject') }),
                            title: this.state.localize('shared.static.warning')
                        },
                        isFetchingAttendance: false
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }
            }

            const payload = {};

            if (this.state.isSchool) {
                payload['date'] = this.props.date;
                payload['class_id'] = classID;
            } else {
                payload['date'] = this.props.date;
                payload['class_id'] = classID;
                payload['subject_id'] = this.state.subject
            }

            let studentAttendance = await this.props.actions.fetchAttendance(new URLSearchParams(payload).toString())
            this.setState({
                attendances: studentAttendance,
                isFetchingAttendance: false
            }, () => {
                setTimeout(this.hideToast, 3000);
            });

            this.handleChart(studentAttendance);
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingAttendance: false })
    }

    //Posting Marking to server
    handleMarkAttendance = async () => {
        try {
            let attendanceQuery = [];
            this.state.attendances.forEach((attendance) => {
                if (attendance.attendance_status) {
                    attendanceQuery.push({
                        student_id: attendance.student_id,
                        status: attendance.attendance_status
                    })
                }
            });

            let payload;

            if (!this.state.isSchool) {
                payload = {
                    class_id: this.state.class,
                    subject_id: this.state.subject,
                    date: this.props.date,
                    student: attendanceQuery
                }
            } else {
                payload = {
                    class_id: this.state.class,
                    date: this.props.date,
                    student: attendanceQuery
                }
            }

            const markAPI = await this.props.actions.markAttendance(payload);

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: markAPI.message,
                    title: this.state.localize('shared.static.success')
                },
                isFetchingAttendance: true
            })

            await this.fetchAttendance();
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
    }

    //Single Student Marking
    setAttendance = (event) => {
        let updatedAttendance = this.state.attendances.map((attendance) => {
            if (attendance.student_id === event.target.id) {
                attendance.attendance_status = event.target.value;
            }
            return attendance;
        });
        this.setState({ attendances: updatedAttendance });
    }

    //All Students Marking
    setAllAttendance = (event) => {
        let updatedAttendance = this.state.attendances.map((attendance) => {
            attendance.attendance_status = event.target.value;
            return attendance;
        });
        this.setState({ attendances: updatedAttendance });
    }

    handleChart = (attendance = []) => {
        if (attendance.length === 0) return;

        const isAttendanceMarked = attendance.every((el) => el.attendance_status !== "");
        const data = {
            present: 0,
            absent: 0,
            late: 0,
            leave: 0
        };

        if (isAttendanceMarked) {
            for (let i = 0; i < attendance.length; i++) {
                switch (attendance[i].attendance_status) {
                    case 'present':
                        data.present++;
                        break;
                    case 'absent':
                        data.absent++;
                        break;
                    case 'late':
                        data.late++;
                        break;
                    default:
                        data.leave++;
                        break;
                }
            }
        }

        const chart = { ...this.state.chart };
        const datasetLength = chart.object.datasets.length;

        const count = Object.values(data);

        chart.object.datasets[datasetLength - 1].data = count;
        chart.show = false;
        chart.have_data = count.some((count) => count > 0);

        this.setState({
            chart
        })
    }

    toggleChart = () => {
        const chart = { ...this.state.chart };

        chart['show'] = !chart['show'];

        this.setState({
            chart
        })
    }

    renderAttendanceList(attendances) {
        if (attendances.length < 1) {
            return <div className="list-container-message-box"> <h3> {this.state.localize(!this.state.isSchool ? 'attendanceComponent.static.collegeFilter' : 'attendanceComponent.static.schoolFilter')} </h3> </div>
        }

        if (!this.state.chart.show) {
            return <div className="attendance-list__wrapper">
                <div className="attendance-list__row__header">
                    <b>{this.state.localize('attendanceComponent.tableHeadings.name')}</b>
                    <b>{this.state.localize('attendanceComponent.tableHeadings.rollNumber')}</b>
                    <b>{this.state.localize('attendanceComponent.tableHeadings.status')}</b>
                    <Can I='update' a='Attendance'>
                        <ButtonGroup onClick={this.setAllAttendance}>
                            <Button value="present"> {this.state.localize('attendanceComponent.static.present')} </Button>
                            <Button value="late"> {this.state.localize('attendanceComponent.static.late')} </Button>
                            <Button value="leave"> {this.state.localize('attendanceComponent.static.leave')} </Button>
                            <Button value="absent"> {this.state.localize('attendanceComponent.static.absent')} </Button>
                        </ButtonGroup>
                    </Can>
                </div>

                <div className="attendance-list__rows_main">
                    {attendances && attendances.map((attendance) => {
                        return (
                            <div className="attendance-list__row" key={attendance.student_id}>
                                <span>{attendance.name}</span>
                                <span>{attendance.roll_number}</span>
                                <span>{attendance.attendance_status ? attendance.attendance_status : this.state.localize('attendanceComponent.static.notMarked')}</span>
                                <Can I='update' a='Attendance'>
                                    <ButtonGroup onClick={this.setAttendance}>
                                        <Button id={attendance.student_id} disabled={attendance.isMarking} value="present" active={attendance.attendance_status === 'present'}>{this.state.localize('attendanceComponent.static.present')}</Button>
                                        <Button id={attendance.student_id} disabled={attendance.isMarking} value="late" active={attendance.attendance_status === 'late'}>{this.state.localize('attendanceComponent.static.late')}</Button>
                                        <Button id={attendance.student_id} disabled={attendance.isMarking} value="leave" active={attendance.attendance_status === 'leave'}>{this.state.localize('attendanceComponent.static.leave')}</Button>
                                        <Button id={attendance.student_id} disabled={attendance.isMarking} value="absent" active={attendance.attendance_status === 'absent'}>{this.state.localize('attendanceComponent.static.absent')}</Button>
                                    </ButtonGroup>
                                </Can>
                            </div>
                        )
                    })}
                </div>

                <Can I='update' a='Attendance'>
                    {attendances && attendances.length > 0 &&
                        <Button
                            variant="success"
                            style={{ float: 'right', margin: '10px' }}
                            onClick={this.handleMarkAttendance}
                            disable={this.state.isFetchingAttendance}>
                            {!this.state.isFetchingAttendance ? <FontAwesomeIcon icon={faSave} /> : ""}
                            {!this.state.isFetchingAttendance ? (' ' + this.state.localize('shared.buttons.save', { type: this.state.localize('pages.attendance', { action: '' }) })) : ' ' + this.state.localize('shared.buttons.wait')}
                        </Button>}
                </Can>
            </div>
        } else {
            return <div className="attendance-list__wrapper">
                <Col xs={12} lg={12} md={12} className="pie-chart">
                    {
                        this.state.chart.have_data ?
                            <Pie data={this.state.chart.object} />
                            :
                            <div className="text-align-center">
                                <h5>
                                    {this.state.localize('attendanceComponent.static.noChart')}
                                </h5>

                                <hr />
                            </div>
                    }
                </Col>
            </div>
        }
    }

    render() {
        const { isFetchingAttendance, attendances } = this.state;
        return (
            <div>
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <HeaderBar />
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} lg={1} md={12}>
                            <h3>{this.state.localize('pages.attendance')}</h3>
                        </Col>

                        <Col xs={12} lg={11} md={12} className="d-flex justify-content-end options-column" style={{ padding: '0px' }}>
                            {
                                this.state.chart.object.datasets[this.state.chart.object.datasets.length - 1].data.length > 0 &&
                                <div className="chart-box">
                                    <input type="checkbox" checked={this.state.chart.show} onChange={() => this.toggleChart()} />

                                    <label className="ml-2">{this.state.localize('attendanceComponent.static.toggleChart')}</label>
                                </div>
                            }

                            <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                <Date />
                            </div>

                            <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                <Can I='read' a='Class'>
                                    {/* <Class default="" onSelect={this.onSelectClass}/> */}

                                    <div>
                                        <select className="form-control form-sm" disabled={isFetchingAttendance} onChange={this.onSelectClass}>
                                            <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class') })}</option>
                                            {this.state.classes.map((c) => {
                                                return (
                                                    <option value={c._id} key={c._id}>{c.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </Can>
                            </div>

                            {
                                !this.state.isSchool &&
                                <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                    {/* <Subject default={this.state.class} onSelect={this.onSelectSubject}/> */}

                                    <select className="form-control form-sm" disabled={isFetchingAttendance} onChange={this.onSelectSubject}>
                                        <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.subject') })}</option>
                                        {this.state.subjects.map((s) => {
                                            return (
                                                <option value={s._id} key={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }


                            <div style={{ marginRight: '5px', marginLeft: '8px' }}>
                                {/* <Button onClick={this.toggleChart} className="mr-2" disabled={!this.state.chart.object.datasets[this.state.chart.object.datasets.length - 1].data.length}>
                                    {this.state.localize('attendanceComponent.static.toggleChart')}
                                </Button> */}

                                <Button
                                    className="search-button"
                                    onClick={this.fetchAttendance}
                                    disable={isFetchingAttendance.toString()}>
                                    {!isFetchingAttendance ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                    {!isFetchingAttendance ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="attendance-list__container">
                    {/* <div className="attendance-list__header">
                        <h3>Attendance</h3>
                        <div className="action-button__wrapper">
                            <Date />
                            <Can I='read' a='Class'>
                                <Class default="" onSelect={this.onSelectClass}/>
                            </Can>
                            <Subject default={this.state.class} onSelect={this.onSelectSubject}/>
                            <Button
                                className="search-button"
                                onClick={this.fetchAttendance}
                                disable={isFetchingAttendance.toString()}>
                                {!isFetchingAttendance ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingAttendance ? (' Search') : (' Wait')}
                            </Button>
                        </div>
                    </div> */} {/*Attendance heading finished */}

                    {
                        isFetchingAttendance ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.renderAttendanceList(attendances)
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        date: state.componentReducer.date,
        attendanceList: state.componentReducer.attendanceList,
        student: state.componentReducer.student
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchAttendance: fetchAttendance,
            markAttendance: markAttendance,
            fetchClasses, fetchSubject,
            fetchClassesByAttendance: fetchClassesByAttendance
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AttendanceList));