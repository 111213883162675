import "react-datepicker/dist/react-datepicker.css";
import {
    React, connect, bindActionCreators,
    FontAwesomeIcon, faPlus, faCircleNotch, faTrashAlt, faCheck, faTimes,
    DatePicker, Button, Link, addQuiz, uploadQuizImage,
    HeaderBar, Form, moment,
    Row, Col, Container, fetchClasses, fetchSubject
} from '../index';
import './style.scss';
import { Toast } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class CreateQuiz extends React.Component {
    state = {
        isCreatingQuiz: false,
        class: null,
        subject: null,
        dropDownValue: 'Text',
        title: '',
        questions: [
            {
                question: { Q: "", type: "text" },
                description: "",
                options: [
                    { choice: "", type: "text", isCorrect: true },
                    { choice: "", type: "text", isCorrect: false },
                ]
            }
        ],
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        toast: {
            show: false,
            message: null,
            title: null
        },
        classes: [],
        subjects: [],
        localize: this.props.t
    }

    componentDidMount() {
        this.getClasses();
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => {
                    this.getSubjects()
                });
            }

        }
    }
    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subjectID: e.target.value });
            }
        }
    }

    async getClasses() {
        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes
            })
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    isEmpty = (value) => value !== undefined && value.toString().trim() === "";

    createQuiz = async () => {
        this.setState({ isCreatingQuiz: true });
        try {
            let questions = [...this.state.questions];

            if (this.state.startDate === null || this.state.startDate === undefined) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('quizComponent.validation.startTime'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return 0;
            }

            if (this.state.endDate === null || this.state.endDate === undefined) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('quizComponent.validation.endTime'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return 0;
            }

            if (this.state.classID === null || this.state.classID === undefined) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.static.class'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return 0;
            }

            if (this.state.subjectID === null || this.state.subjectID === undefined) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.static.subject'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return 0;
            }

            if (this.isEmpty(this.state.title)) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('quizComponent.validation.title'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return 0;
            }

            for (let i = 0; i < questions.length; i++) {
                if (this.isEmpty(questions[i].question.Q)) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('quizComponent.validation.question'),
                            title: this.state.localize('shared.static.warning')
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }

                /* if (this.isEmpty(questions[i].description)) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: "Question description is missing."
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                } */

                if (questions[i].question.type === "img" && !questions[i].question.Q.startsWith('https')) {
                    let questionImage = await this.props.actions.uploadQuizImage(document.getElementById(`question-${i}`).files[0]);

                    if (questionImage.success) {
                        questions[i].question.Q = questionImage.data;
                    }
                }

                let hasOneCorrectOption = false;

                for (let j = 0; j < questions[i].options.length; j++) {
                    if (questions[i].options[j].isCorrect) {
                        hasOneCorrectOption = true;
                    }

                    if (this.isEmpty(questions[i].options[j].choice)) {
                        this.setState({
                            toast: {
                                show: !this.state.toast.show,
                                message: this.state.localize('quizComponent.validation.questionMissing'),
                                title: this.state.localize('shared.static.warning')
                            }
                        });

                        setTimeout(this.hideToast, 3000);

                        return;
                    }

                    if (questions[i].options[j].type === "img" && !questions[i].options[j].choice.startsWith('https')) {
                        let optionImage = await this.props.actions.uploadQuizImage(document.getElementById(`question-${i}-option-${j}`).files[0]);

                        if (optionImage.success) {
                            questions[i].options[j].choice = optionImage.data;
                        }
                    }

                }

                if (!hasOneCorrectOption) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('quizComponent.validation.correctOption', { counter: i + 1}),
                            title: this.state.localize('shared.static.warning')
                        }
                    });

                    setTimeout(this.hideToast, 3000);

                    return;
                }
            }

            let createQuiz = await this.props.actions.addQuiz(
                this.state.title,
                this.state.classID,
                this.state.subjectID,
                questions,
                moment(this.state.startDate),
                moment(this.state.endDate)
            );
            if (createQuiz) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.created', { module: this.state.localize('pages.quiz', {action: ''}) }),
                        title: this.state.localize('shared.static.success')
                    }
                });

                setTimeout(this.hideToast, 3000);

                setTimeout(() => this.props.history.replace('/quiz'), 3000);
            }
        } catch (error) {
            /* this.setState({ error: error.response.data.message || this.state.localize('shared.static.serverError') });  */

            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast, 3000);
        }
        this.setState({ isCreatingQuiz: false });
    }

    /* onSelectClass = (classID) => { this.setState({ class: classID }); }
    onSelectSubject = (subjectID) => { this.setState({ subject: subjectID }); } */
    handleTitle = (event) => { this.setState({ title: event.target.value }); }
    handleStartDate = (date) => {
        const start = new Date(date);
        this.setState({ startDate: start });
    }
    handleEndDate = (date) => {
        const end = new Date(date);
        this.setState({ endDate: end });
    }

    handleQuestion = i => e => {
        let questions = [...this.state.questions];
        questions[i].question.Q = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions)
        });
    }

    handleQuestionDescription = i => e => {
        let questions = [...this.state.questions];
        questions[i].description = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions[i].description)
        });
    }

    handleAnswer = (index1, index2) => e => {
        let questions = [...this.state.questions];
        questions[index1].options[index2].choice = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions)
        });
    }

    addQuestion = e => {
        e.preventDefault()
        let questions = this.state.questions.concat([{
            question: { Q: "", type: "text" },
            description: "",
            options: [{ choice: "", type: "text", isCorrect: false }, { choice: "", type: "text", isCorrect: false }]
        }])
        this.setState({
            questions
        })
    }

    addOption = i => e => {
        e.preventDefault();
        let options = this.state.questions[i].options.push({ choice: '', type: 'text', isCorrect: false })
        this.setState({
            options
        })
    }

    deleteQuestion = i => e => {
        e.preventDefault()
        let questions = [...this.state.questions];

        if (i !== -1) {
            questions.splice(i, 1);

            this.setState({
                questions
            })
        }
    }

    deleteOption = (i, j) => e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        if (j !== -1) {
            questions[i].options.splice(j, 1);

            this.setState({
                questions
            })
        }
    }

    handleQuestionType = i => e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        if (e.target.value === "Image") {
            questions[i].question.type = "img";
            questions[i].question.Q = "";
        } else {
            questions[i].question.type = "text";
            questions[i].question.Q = "";
        }

        this.setState({
            questions
        })
    }

    correctOptionSelected = (i, j) => () => {
        let questions = [...this.state.questions];

        questions[i].options.map((option) => {
            if (option.isCorrect) {
                option.isCorrect = false;
            }

            return option;
        })

        questions[i].options[j].isCorrect = true;

        this.setState({
            questions
        })
    }

    handleQuestionOption = (i, j) => e => {
        let questions = [...this.state.questions];
        questions[i].options[j].choice = e.target.value;
        this.setState({ questions }, () => {
            console.log("Question", this.state.questions)
        });
    }

    handleQuestionOptionType = (i, j) => e => {
        e.preventDefault();
        let questions = [...this.state.questions];

        if (e.target.value === "Image") {
            questions[i].options[j].type = "img";
            questions[i].options[j].choice = "";
        } else {
            questions[i].options[j].type = "text";
            questions[i].options[j].choice = "";
        }

        this.setState({
            questions
        })
    }

    imageQuestionHandler = i => e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let questions = [...this.state.questions];

                questions[i].question.Q = reader.result;

                this.setState({
                    questions
                })
            }
        }

        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    imageQuestionOptionHandler = (i, j) => e => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let questions = [...this.state.questions];

                questions[i].options[j].choice = reader.result;

                this.setState({
                    questions
                })
            }
        }
        
        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isCreatingQuiz: false
        })
    }

    render() {
        const { isCreatingQuiz, startDate, endDate, questions, classes, subjects } = this.state
        return (
            <div>
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                    <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <HeaderBar />
                <div className="create-quiz-list__container">
                    <div className="create-quiz-list__header">
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{this.state.localize('pages.quiz', { action: this.state.localize('shared.static.create')})}</h3>
                        <div className="action-button__wrapper">
                            <Link to={{ pathname: `/quiz` }} >
                                <Button
                                    variant="danger">
                                    {this.state.localize('shared.buttons.close')}
                                </Button>
                            </Link>
                            <Button
                                className="search-button"
                                onClick={this.createQuiz}>
                                {!isCreatingQuiz ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isCreatingQuiz ? (' ' + this.state.localize('shared.buttons.create')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                        </div>
                    </div> {/* Quiz Header closed */}
                    <div className="create-quiz-list__header">
                        <Container fluid={true} style={{ padding: '0px' }}>
                            <Row className="top-options-row">
                                <Col lg={2} md={12} xs={12}>
                                    <select className="form-control form-sm" onChange={this.onSelectClass}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                        {classes.map((c) => {
                                            return (
                                                <option value={c._id} key={c._id}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                <Col lg={2} md={12} xs={4}>
                                    <select className="form-control form-sm" onChange={this.onSelectSubject}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                        {subjects.map((s) => {
                                            return (
                                                <option value={s._id} key={s._id}>{s.title}</option>
                                            )
                                        })}
                                    </select>
                                </Col>

                                <Col lg={2} md={12} xs={12}>
                                    <Form.Control type="text" value={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} readOnly placeholder={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={startDate}
                                        onChange={date => this.handleStartDate(date)}
                                        timeInputLabel="Time:"
                                        placeholderText={startDate ? startDate : this.state.localize('quizComponent.shared.startDate')}
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                    />
                                </Col>

                                <Col lg={2} md={12} xs={12} className="text-align-right">
                                    <DatePicker
                                        className="date_picker form-control form-sm"
                                        selected={endDate}
                                        onChange={date => this.handleEndDate(date)}
                                        placeholderText={endDate ? endDate : this.state.localize('quizComponent.shared.endDate')}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                    />
                                </Col>
                            </Row>
                        </Container>
                        {/* <div className="action2-button__wrapper">
                            <Class default="" onSelect={this.onSelectClass} />
                            <Subject default={this.state.class} onSelect={this.onSelectSubject} />
                            <h3 style={{ fontWeight: '200' }}>{localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName')}</h3>
                            <div>
                                <Form.Control type="number" placeholder="Duration in minutes" onChange={this.handleDuration} />
                            </div>
                        </div>

                        <div className="action2-button__wrapper">
                            <DatePicker
                                className="date_picker"
                                selected={startDate}
                                onChange={date => this.handleStartDate(date)}
                                timeInputLabel="Time:"
                                placeholderText={startDate ? startDate : { this.state.localize('quizComponent.shared.startDate') }}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />

                            <DatePicker
                                className="date_picker"
                                selected={endDate}
                                onChange={date => this.handleEndDate(date)}
                                placeholderText={endDate ? endDate : "END DATE"}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />
                        </div> */}
                    </div> {/* Quiz Header closed */}
                    <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.title')} onChange={this.handleTitle} />
                    <Container className="section-question-answer" fluid={true}>
                        <h3 style={{ textTransform: 'uppercase', fontWeight: '100' }}>{ this.state.localize('quizComponent.shared.questionSection') } </h3>
                        {questions.map((question, i) => {
                            return (
                                <div className="width-100" key={i}>
                                    <div className="width-100">
                                        <Row>
                                            <Col lg={12} md={12} xs={1}>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <hr />
                                            </Col>

                                            <Col xs={12} md={12} lg={12}>
                                                <h4 style={{ fontWeight: '100' }}>
                                                    { this.state.localize('quizComponent.shared.questionNumber', { number: i + 1 }) }
                                                </h4>
                                            </Col>
                                        </Row>

                                        {
                                            question.question.type === "img" && question.question.Q &&

                                            <Row>
                                                <Col xs={12} md={4} lg={3}>
                                                    <img src={question.question.Q} alt="" className="img thumbnail" />
                                                </Col>
                                            </Row>
                                        }

                                        <Row className="margin-top-5 margin-bottom-5">
                                            {
                                                question.question.type === "text" &&
                                                question.question.Q &&
                                                <Col xs={12} md={12} lg={12} style={{ paddingRight: '0px' }}>
                                                    <h3 style={{ fontWeight: '100' }}>
                                                        {question.question.Q}
                                                    </h3>
                                                </Col>
                                            }
                                            <Col xs={12} md={6} lg={9} style={{ paddingRight: '0px' }}>
                                                {question.question.type === "img" ? <Form.File accept="image/*" id={"question-" + i} onChange={this.imageQuestionHandler(i)} /> : <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.question', { count: i + 1})} value={question.question.Q} onChange={this.handleQuestion(i)} />}
                                            </Col>
                                            <Col xs={12} md={4} lg={2} style={{ paddingRight: '0px' }}>
                                                <select className="form-control form-sm" onChange={this.handleQuestionType(i)}>
                                                    <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                    <option key={"image"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                </select>
                                            </Col>
                                            <Col xs={12} md={1} lg={1} className="text-align-right">
                                                <Button
                                                    variant="danger"
                                                    onClick={this.deleteQuestion(i)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="margin-top-5" style={{ marginBottom: '15px' }}>
                                            <Col xs={12} md={12} lg={12}>
                                                <Form.Control type="text" placeholder={this.state.localize('quizComponent.placeholders.description')} value={question.description} onChange={this.handleQuestionDescription(i)} />
                                            </Col>
                                        </Row>
                                    </div>

                                    {question.options.map((option, j) => {
                                        return (
                                            <div key={j}>
                                                <Row>
                                                    <Col lg={12} md={12} xs={1}>
                                                        <hr />
                                                    </Col>
                                                </Row>

                                                {
                                                    option.type === "img" && option.choice &&
                                                    <Row>
                                                        <Col xs={12} md={4} lg={3}>
                                                            <img src={option.choice} alt="" className="img thumbnail" />
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row className="margin-top-5 margin-bottom-5">
                                                    <Col lg={8} style={{ display: 'flex', paddingRight: '0px' }}>
                                                        {/* <Form.Check type="radio" name={"option-" + i} id={j} checked={option.isCorrect} onChange={this.correctOptionSelected(i, j)} /> */}
                                                        {option.type === "img" ? <Form.File className="question-option" accept="image/*" id={"question-" + i + "-option-" + j} onChange={this.imageQuestionOptionHandler(i, j)} /> : <Form.Control className="question-option" type="text" placeholder={this.state.localize('quizComponent.placeholders.option', { count: j + 1})} value={option.choice} onChange={this.handleQuestionOption(i, j)} />}
                                                    </Col>
                                                    <Col lg={3} sm={10} style={{ paddingRight: '0px' }}>
                                                        <select className="form-control form-sm question-option" onChange={this.handleQuestionOptionType(i, j)}>
                                                            <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                            <option key={"image"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                        </select>
                                                    </Col>
                                                    <Col lg={1} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {
                                                            option.isCorrect ?
                                                                <Button className="question-option"
                                                                    variant="success"
                                                                    style={{ marginRight: '5px' }}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </Button>
                                                                :
                                                                <Button className="question-option"
                                                                    variant="warning"
                                                                    onClick={this.correctOptionSelected(i, j)} style={{ marginRight: '5px' }}>
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </Button>
                                                        }
                                                        <Button className="question-option"
                                                            variant="danger"
                                                            onClick={this.deleteOption(i, j)} style={{ marginRight: '5px' }}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {/* <Row className="margin-top-5 margin-bottom-5">
                                                    <Col lg={8} style={{ display: 'flex' }}>
                                                        <Form.Check type="radio" name={"option-" + i} id={j} checked={option.isCorrect} onChange={this.correctOptionSelected(i, j)} />
                                                        {option.type === "img" ? <Form.File accept="image/*" id={"question-" + i + "-option-" + j} onChange={this.imageQuestionOptionHandler(i, j)} /> : <Form.Control type="text" placeholder="Question" value={option.choice} onChange={this.handleQuestionOption(i, j)} />}
                                                    </Col>
                                                    <Col lg={2}>
                                                        <select className="form-control form-sm" onChange={this.handleQuestionOptionType(i, j)}>
                                                            <option key={"text"}>{ this.state.localize('quizComponent.shared.text') }</option>
                                                            <option key={"image"}>{ this.state.localize('quizComponent.shared.image') }</option>
                                                        </select>
                                                    </Col>
                                                    <Col lg={1} style={{ textAlign: 'right' }}>
                                                        <Button
                                                            variant="danger"
                                                            onClick={this.deleteOption(i, j)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                                {/* <Row>
                                                    <Col lg={12} md={12} xs={1}>
                                                        <hr />
                                                    </Col>
                                                </Row> */}
                                            </div>
                                        )
                                    })}

                                    <Row>
                                        <Col lg={1}>
                                            {
                                                question.options.length < 4 &&
                                                <Button onClick={this.addOption(i)}> <FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newOption') }</Button>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                        {/* {questions.map((question, index) => (
                            <Row key={index} className="question">
                                <Col xs={12} md={6} lg={8}> <Form.Control type="text" placeholder="Question" value={question.question.Q} onChange={this.handleQuestion(index)} /> </Col>
                                <Col xs={12} md={6} lg={2}>
                                    <Button
                                        variant="danger"
                                        onClick={this.deleteQuestion(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </Col>
                                <Col xs={12} md={6} lg={8}>
                                    <Form.Control type="text" placeholder="Description" value={question.description} onChange={this.handleQuestionDescription(index)} />
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                </Col>

                                <div className="answer">
                                    {question.options.map((option, index2) => (
                                        <Row key={index2} className="answer-row">
                                            <Col lg={1}>
                                                <Form.Check type="radio" name="formHorizontalRadios" id={index2} />
                                            </Col>
                                            <Col lg={9}>
                                                <Form.Control type="text" placeholder="Add Option here" onChange={this.handleAnswer(index, index2)} />
                                            </Col>
                                            <Col lg={2}>
                                                <Button
                                                    variant="danger"
                                                    onClick={this.deleteOption(index2)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button onClick={this.addOption(index)}> <FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newOption') }</Button>
                                </div>
                            </Row>
                        ))} */}
                    </Container>
                    <Container className="add-question">
                        <Button onClick={this.addQuestion}><FontAwesomeIcon icon={faPlus} /> { this.state.localize('quizComponent.shared.newQuestion') }</Button>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ addQuiz, uploadQuizImage, fetchClasses, fetchSubject }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateQuiz));

/*

// Dropdowns can be re-used when applying for IMAGE questions:

    <DropdownButton id="dropdown-basic-button" title={this.state.dropDownValue}>
        {this.state.types.map((type) =>
            <Dropdown.Item
                id={type.id}
                key={type.id}
                eventKey={type.id}
                onClick={() => this.handleChange(type.text)}>
                {type.text}
            </Dropdown.Item>)}
    </DropdownButton>

    <DropdownButton id="dropdown-basic-button" title={this.state.dropDownValue}>
        {this.state.types.map((type) =>
            <Dropdown.Item
                id={type.id}
                key={type.id}
                eventKey={type.id}
                onClick={() => this.handleChange(type.text)}>
                {type.text}
            </Dropdown.Item>)}
    </DropdownButton>
    */