import {
    React, connect, bindActionCreators,
    FontAwesomeIcon, faSearch, faCircleNotch,
    Button, Link, moment,
    HeaderBar, fetchStudentResult,
    Container, Row, Col, Pagination, fetchSubject
} from '../index';
import { Toast } from "react-bootstrap";
import './style.scss';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ar';
import i18n from '../../i18n';

class ResultQuiz extends React.Component {
    state = {
        isFetchingResult: false,
        dummyData: [],
        results: [],
        pagination: {
            itemsPerPage: 10,
            totalItems: 0,
            currentPage: 1,
            controlMarkup: []
        },
        subjects: [],
        toast: {
            show: false,
            message: null,
            title: null
        },
        localize: this.props.t
    }
    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== this.state.localize('quizComponent.resultModal.getAll')) {
                this.setState({ subject: e.target.value });
            } else {
                this.setState({ subject: null })
            }
        }
    }

    componentDidMount() {
        this.fetchResult();
        this.getSubjects();
    }

    setPaginationPage = (i) => {
        let pagination = { ...this.state.pagination };

        pagination.currentPage = i;

        this.setState({
            pagination
        }, () => {
            this.fetchResult();
        })
    }
    
    changePaginationLimit = (e) => {
        let pagination = { ...this.state.pagination };

        pagination.itemsPerPage = e.target.value;

        this.setState({
            pagination
        }, () => {
            this.fetchResult();
        })
    }
    fetchResult = async () => {
        this.setState({ isFetchingResult: true })
        try {
            let result = await this.props.actions.fetchStudentResult(this.state.subject, this.state.pagination.currentPage, this.state.pagination.itemsPerPage);

            let arr = [];

            for (let i = 0; i < Math.ceil(result.count / this.state.pagination.itemsPerPage); i++) {
                arr.push(<Pagination.Item key={i} active={i + 1 === this.state.pagination.currentPage} onClick={() => this.setPaginationPage(i + 1)}>{i + 1}</Pagination.Item>)
            }

            this.setState({
                results: result.list,
                pagination: {
                    itemsPerPage: result.limit,
                    totalItems: result.count,
                    currentPage: result.page,
                    controlMarkup: arr
                }
            })
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingResult: false })
    }

    async getSubjects() {
        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.class; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    title: this.state.localize('shared.static.warning'),
                    message: error.response.data.message
                }
            });

            setTimeout(this.hideToast, 3000);
        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isCreatingQuiz: false
        })
    }

    quizzesResultList(results) {
        // if(results.length<1){
        //     return <div className="list-container-message-box"> <h3> Search for Quiz Results </h3> </div> 
        // }

        return <div className="result-quiz-list__wrapper">
            <div className="result-quiz-list__heading">
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.title')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.teacherName')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.subject')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.submittedDate')}</b>
                {/* <b>Date</b> */}
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.duration')}</b>
                <b>{this.state.localize('quizComponent.resultModal.tableHeadings.score')}</b>
            </div>
            {/* Map data here */}
            {results && results.map((result, i) => {
                return (
                    <div className="result-quiz-list__row" key={i}>
                        <span>{result.quiz_title}</span>
                        <span>{result.teacherName}</span>
                        <span>{result.subjectName}</span>
                        <span>{moment.utc(result.quiz_submitted).locale(i18n.language).format("DD/MM/YYYY")}</span>
                        {/* <span>{result.created_at}</span> */}
                        <span>{result.duration}mins</span>
                        <span>{result.correct_answer} / {result.total_questions}</span>
                    </div>
                )
            })}
        </div>
    }

    render() {
        const { isFetchingResult, results, pagination, subjects } = this.state;
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="result-quiz-list__container">
                    <div className="result-quiz-list__header">
                        <h3>{this.state.localize('quizComponent.resultModal.title')}</h3>
                        <div className="action-button__wrapper">
                            {/* <Subject default={this.state.class} onSelect={this.onSelectSubject} /> */}
                            <select className="form-control form-sm" onChange={this.onSelectSubject}>
                                <option>{this.state.localize('quizComponent.resultModal.getAll')}</option>
                                {subjects.map((s) => {
                                    return (
                                        <option value={s._id} key={s._id}>{s.title}</option>
                                    )
                                })}
                            </select>
                            <Button
                                variant="primary"
                                onClick={this.fetchResult}
                                disable={isFetchingResult.toString()}>
                                {!isFetchingResult ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingResult ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                            <Link to={{ pathname: `/quiz` }} >
                                <Button
                                    variant="secondary"> { this.state.localize('shared.buttons.back')}
                                </Button>
                            </Link>
                        </div>
                    </div> {/* Quiz Header closed */}

                    {
                        isFetchingResult ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.quizzesResultList(results)
                    }
                </div>

                <Container fluid={true}>
                    <Row>
                        {
                            pagination.totalItems > 0 &&
                            <Col md={4} lg={4} xs={12}>
                                <select className="form-control form-sm" onChange={this.changePaginationLimit} style={{ maxWidth: '250px' }}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </Col>
                        }

                        <Col md={8} lg={8} xs={12}>
                            <Pagination style={{ float: 'right' }}>
                                {
                                    pagination.controlMarkup
                                }
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps() {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchStudentResult, fetchSubject }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResultQuiz));