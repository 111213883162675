import React from 'react';
import { connect } from 'react-redux';
import { fetchDate } from './duck/actions';

class date extends React.Component {
    handleDate = (e) => {
        this.props.fetchDate(e.target.value)
    }

    render() {
        return (
            <div>
                <label htmlFor="todaydate"><input className="dateelement" type="date" id="todaydate" name="todaydate"
                    onChange={this.handleDate} />
                </label>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        date: state.componentReducer.date
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDate: (date) => dispatch(fetchDate(date))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(date);